// color_picker_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    colors: {
      type: Array,
      default: [
        // Reds & Pinks
        "#ff0000",
        "#ff4444",
        "#ff6b6b",
        "#ff1493",
        "#ff69b4",
        // Oranges & Browns
        "#ff8c00",
        "#ffa500",
        "#ff7f50",
        "#d2691e",
        "#8b4513",
        // Yellows
        "#ffd700",
        "#ffff00",
        "#f0e68c",
        "#bdb76b",
        "#daa520",
        // Greens
        "#32cd32",
        "#00ff00",
        "#98fb98",
        "#3cb371",
        "#2e8b57",
        // Blues
        "#0000ff",
        "#1e90ff",
        "#87ceeb",
        "#4169e1",
        "#000080",
        // Purples & Pinks
        "#8a2be2",
        "#9400d3",
        "#ba55d3",
        "#9370db",
        "#ee82ee",
      ],
    },
    selected: { type: String, default: "#ff0000" },
  };

  connect() {
    // Style the trigger dot
    this.element.classList.add("rounded-circle", "border", "shadow-sm", "p-3");
    this.element.style.backgroundColor = this.selectedValue;
    this.element.style.cursor = "pointer";

    // Setup popover attributes
    this.element.setAttribute("data-bs-toggle", "popover");
    this.element.setAttribute("data-bs-placement", "bottom");
    this.element.setAttribute("data-bs-html", "true");
    this.element.setAttribute("data-bs-container", "body");
    this.element.setAttribute("data-bs-custom-class", "color-picker-popover");

    // Create popover content
    const content = `
    <div class="d-flex flex-wrap gap-2" style="max-width: 300px;">
      ${this.colorsValue
        .map(
          (color) => `
        <div 
          class="rounded-circle border-0 shadow-sm p-3" 
          style="background-color: ${color}; cursor: pointer; width: 24px; height: 24px; display: inline-block;"
          data-action="click->color-picker#selectColor"
          data-color="${color}">
        </div>
      `
        )
        .join("")}
      <input type="color" 
        class="form-control form-control-color p-3 rounded-circle" 
        data-action="change->color-picker#selectCustomColor"
        value="${this.selectedValue}"
        title="Choose custom color">
    </div>
  `;

    this.element.setAttribute("data-bs-content", content);

    const popoverTriggerList = document.querySelectorAll(
      '[data-bs-toggle="popover"]'
    );
    const popoverList = [...popoverTriggerList].map(
      (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
    );
  }

  selectColor(event) {
    const color = event.target.dataset.color;
    this.selectedValue = color;
    this.element.style.backgroundColor = color;
    // Hide popover using Bootstrap's data API
    bootstrap.Popover.getInstance(this.element).hide();
    this.dispatch("colorSelected", { detail: { color } });
  }

  selectCustomColor(event) {
    const color = event.target.value;
    this.selectedValue = color;
    this.element.style.backgroundColor = color;
    bootstrap.Popover.getInstance(this.element).hide();
    this.dispatch("colorSelected", { detail: { color } });
  }
}
