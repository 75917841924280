import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comments-panel"
export default class extends Controller {
  static targets = ["field"];

  connect() {
    const infoPanel = document.getElementById("info_panel");
    infoPanel?.classList.add("comments");

    document.body.classList.add("info-panel-active");

    this.scroll();
  }

  scroll() {
    let lastComment = document
      .getElementById("info_panel")
      ?.querySelector(".comment:last-child");

    lastComment?.scrollIntoView();
  }

  submit(ev) {
    if (this.hasFieldTarget) {
      window.setTimeout(() => {
        this.fieldTarget.value = "";
        this.fieldTarget.focus();
      }, 500);
    }
  }

  close(ev) {
    document.body.classList.remove("info-panel-active");
    const infoPanel = document.getElementById("info_panel");
    infoPanel?.classList.remove("comments");
  }

  closeCommentPanel(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    this.element.classList.remove("comments-active");
    document
      .querySelector(".comments-active")
      .classList.remove("comments-active");
  }
}
