import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="item-share"
export default class extends Controller {
  static targets = [
    "option",
    "btn",
    "label",
    "spinner",
    "close",
    "btnWrapper",
    "copyWrapper",
    "copyBtn",
    "copyUrl",
  ];
  static values = { url: String };

  connect() {
    console.log("item-share#connect");

    this.reset();
  }

  reset() {
    this.btnTarget.disabled = false;
    this.closeTarget.style.display = "block";
    this.labelTarget.style.display = "block";
    this.spinnerTarget.classList.add("d-none");
    this.btnWrapperTarget.classList.remove("d-none");
    this.copyWrapperTarget.classList.add("d-none");
  }

  share(ev) {
    ev.preventDefault();

    this.btnTarget.disabled = true;
    this.closeTarget.style.display = "none";
    this.labelTarget.style.display = "none";
    this.spinnerTarget.classList.remove("d-none");

    // generate share link
    const data = {};
    this.optionTargets.forEach((el) => {
      data[el.dataset.name] = el.checked;
    });

    fetch(this.urlValue, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ data: data }),
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((body) => {
            console.log("body", body.url);
            this.shareUrl = body.url;
            this.copyUrlTarget.value = body.url;
            this.btnWrapperTarget.classList.add("d-none");
            this.copyWrapperTarget.classList.remove("d-none");
            this.copyUrlTarget.select();
          });
        }
      })
      .catch((err) => console.log(err));
  }

  copy(ev) {
    ev.preventDefault();

    navigator.clipboard.writeText(this.shareUrl);

    window.notyf.success({
      message: "Share link copied to your clipboard",
      dismissible: true,
    });
  }
}
