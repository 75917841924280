import { Controller } from "@hotwired/stimulus";
import { showPrompt, headers, jsonHeaders } from "../utils";

export default class extends Controller {
  static values = { projectOnly: Boolean };
  connect() {
    console.log("Finder controller connected", this.projectOnlyValue);

    // check for selected
    const selected = this.element.querySelector(".selected");
    if (selected) {
      if (selected.classList.contains("project")) {
        const id = selected.dataset.projectId;
        const token = selected.dataset.projectToken;
        const project = { id, token };

        this.dispatch("selected", {
          detail: { project },
          bubbles: true,
        });
      }
    }
  }

  clearSelections(event) {
    this.dispatch("selected", {
      detail: {},
      bubbles: true,
    });
  }

  projectSelected(event) {
    if (this.projectOnlyValue) {
      event.preventDefault();

      const classArray = ["border", "border-primary", "border-3", "rounded"];

      document
        .getElementById("finder")
        .querySelectorAll(".project")
        .forEach((el) => el.classList.remove(...classArray));

      event.currentTarget.classList.add(...classArray);
    }

    const id = event.currentTarget.dataset.projectId;
    const token = event.currentTarget.dataset.projectToken;
    const project = { id, token };

    this.dispatch("selected", {
      detail: { project },
      bubbles: true,
    });
  }

  boardSelected(event) {
    const project = event.params.project;
    const board = event.params.board;

    this.dispatch("selected", {
      detail: { project, board },
      bubbles: true,
    });
  }

  groupSelected(event) {
    event.preventDefault();
    const project = event.params.project;
    const board = event.params.board;
    const group = event.params.group;

    const classArray = ["border", "border-primary", "border-3", "rounded"];

    document
      .getElementById("finder")
      .querySelectorAll(".group")
      .forEach((el) => el.classList.remove(...classArray));

    event.currentTarget.classList.add(...classArray);

    this.dispatch("selected", {
      detail: { project, board, group },
      bubbles: true,
    });
  }

  addNewProject(event) {
    event.preventDefault();
    const url = event.params.url;

    showPrompt("New Project", "Enter the project name:", (value) => {
      if (value !== "") {
        const body = {
          project: {
            name: value,
          },
        };

        fetch(url, {
          method: "POST",
          headers: headers(),
          body: JSON.stringify(body),
        })
          .then((resp) => resp.json())
          .then((json) => {
            if (this.projectOnlyValue) {
              Turbo.visit(
                `/finder?project_only=true&selected_project_id=${json.token}`,
                {
                  frame: "finder",
                }
              );
            } else {
              Turbo.visit(`/finder?project_id=${json.token}`, {
                frame: "finder",
              });
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    });
  }

  addNewBoard(event) {
    event.preventDefault();
    const url = event.params.url;

    showPrompt("New Board", "Enter the board name:", (value) => {
      if (value !== "") {
        const body = {
          board: {
            name: value,
          },
        };

        fetch(url, {
          method: "POST",
          headers: jsonHeaders(),
          body: JSON.stringify(body),
        })
          .then((resp) => resp.json())
          .then((json) => {
            console.log(json);
            Turbo.visit(`/finder?board_id=${json.board.token}`, {
              frame: "finder",
            });
          })
          .catch((error) => {
            window.notyf.error({
              message: `Error creating board - ${error}`,
              dismissible: true,
            });
          });
      }
    });
  }

  addNewGroup(event) {
    event.preventDefault();
    const url = event.params.url;
    const boardId = event.params.boardId;

    showPrompt("New Group", "Enter the group name:", (value) => {
      if (value !== "") {
        const body = {
          container: {
            label: value,
          },
        };

        fetch(url, {
          method: "POST",
          headers: jsonHeaders(),
          body: JSON.stringify(body),
        })
          .then((resp) => resp.json())
          .then((json) => {
            Turbo.visit(`/finder?board_id=${boardId}`, {
              frame: "finder",
            });
          })
          .catch((error) => {
            window.notyf.error({
              message: `Error creating board - ${error}`,
              dismissible: true,
            });
          });
      }
    });
  }
}
