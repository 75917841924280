import { Controller } from "@hotwired/stimulus";
import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";

export default class extends Controller {
  static values = { extensionImg: String };

  connect() {
    const activeTour = localStorage.getItem("tourActive");
    if (activeTour == "true" || activeTour == true) {
      this.startTour();
      localStorage.setItem("tourActive", false);
    }
  }

  startTour() {
    const el = this.element.querySelector(".grid-item");

    // make sure it's there
    if (!el) {
      return;
    }

    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      classPrefix: "wecora-",
      defaultStepOptions: {
        classes: "shepherd-element",
        scrollTo: false,
      },
    });

    tour.addStep({
      id: "addedItem",
      title: "You're On Your Way!",
      text: "Everything you capture, text or share to your library will be saved here for you to easily and quickly find it when you need it later.",
      attachTo: {
        element: el,
        on: "top",
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 50 })],
      },
      buttons: [
        {
          text: "Next",
          action: () => {
            el.click();
            tour.next();
          },
        },
      ],
      modalOverlayOpeningPadding: 10,
      useModalOverlay: false,
    });

    tour.addStep({
      id: "sidePanelStep",
      title: "All In One Place",
      text: "And unlike just saving a photo, all the details that matter are just a click away",
      attachTo: {
        element: "#sidePanel",
        on: "left",
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 50 })],
      },
      buttons: [
        {
          text: "Back",
          action: tour.back,
        },
        {
          text: "Next",
          action: tour.next,
        },
      ],
      modalOverlayOpeningPadding: 10,
      useModalOverlay: false,
    });

    tour.addStep({
      id: "complete",
      title: "Welcome to Wecora",
      text: `You've now mastered just one of the many things you can do with Wecora. Go find your favorite items to add to your library now, or click here to learn more about what you can do.`,
      when: {
        show: () => {
          const stepElement = document.querySelector(".shepherd-step");
        },
      },
      buttons: [
        {
          text: "Exit",
          action: tour.cancel,
        },
      ],
    });

    tour.start();
  }
}
