import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sms"
export default class extends Controller {
  static targets = ["skip"];

  connect() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const continentalUSBounds = {
          latMin: 24.396308,
          latMax: 49.384358,
          lonMin: -125.0,
          lonMax: -66.93457,
        };

        const alaskaBounds = {
          latMin: 51.3,
          latMax: 71.5,
          lonMin: -179.1,
          lonMax: -129.9,
        };

        const hawaiiBounds = {
          latMin: 18.5,
          latMax: 22.2,
          lonMin: -160.24,
          lonMax: -154.8,
        };

        const isInContinentalUS =
          latitude >= continentalUSBounds.latMin &&
          latitude <= continentalUSBounds.latMax &&
          longitude >= continentalUSBounds.lonMin &&
          longitude <= continentalUSBounds.lonMax;

        const isInAlaska =
          latitude >= alaskaBounds.latMin &&
          latitude <= alaskaBounds.latMax &&
          longitude >= alaskaBounds.lonMin &&
          longitude <= alaskaBounds.lonMax;

        const isInHawaii =
          latitude >= hawaiiBounds.latMin &&
          latitude <= hawaiiBounds.latMax &&
          longitude >= hawaiiBounds.lonMin &&
          longitude <= hawaiiBounds.lonMax;

        if (!isInContinentalUS && !isInAlaska && !isInHawaii) {
          this.skipTarget.classList.add("show");
        }
      });
    }
  }
}
