import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="items-assign-to-board"
export default class extends Controller {
  static targets = ["btn"];
  static values = { id: Number, projectToken: String };

  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false,
    });

    this.element.addEventListener("show.bs.modal", (ev) => {
      Turbo.visit(
        "/finder?project_only=true&exclude_project=${this.projectTokenValue}",
        { frame: "finder" }
      );
    });
  }

  finderSelected(ev) {
    if (ev.detail.project) {
      this.projectId = ev.detail.project.id;
      this.btnTarget.classList.remove("d-none");
      this.btnTarget.disabled = false;
    } else {
      this.btnTarget.classList.add("d-none");
    }
  }

  move(ev) {
    ev.preventDefault();

    if (this.projectId) {
      this.btnTarget.disabled = true;

      window.notyf.success({
        message: "Moving...",
        dismissible: true,
      });

      const body = {
        item: {
          project_id: this.projectId,
        },
      };

      const url = `/items/${this.idValue}.json`;

      fetch(url, {
        method: "PUT",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((json) => {
              window.notyf.dismissAll();
              window.notyf.success(
                `Movied to <a href="/projects/${json.item.project.token}">${json.item.project.name}</a>`
              );

              // remove the item if it exists in the library for the current project
              document.getElementById(`grid_item_${this.idValue}`)?.remove();

              document.getElementById("details")?.reload();

              const modal = bootstrap.Modal.getInstance("#bsTurboModal");
              modal.hide();
            });
          } else {
            resp.json().then((json) => {
              this.btnTarget.disabled = false;
              window.notyf.dismissAll();
              window.notyf.error({
                message: `Error moving item - ${json.errors}`,
                dismissible: true,
              });
            });
          }
        })
        .catch((error) => {
          index++;
          this.btnTarget.disabled = false;
          this.btnTarget.innerHTML = "Move";

          window.notyf.dismissAll();
          window.notyf.error({
            message: `Error moving item - ${error}`,
            dismissible: true,
          });
        });
    } else {
      console.log("error - no project selected");
    }
  }
}
