import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = { options: Object };

  connect() {
    const defaults = {
      container: this.element,
      title: this.element.title,
      delay: { show: this.element.dataset.delay || 0, hide: 0 },
    };
    const options = Object.assign({}, defaults, this.optionsValue);

    new bootstrap.Tooltip(this.element, options);

    this.element.style.cursor = "default";
  }
}
