import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="inline-editor"
export default class extends Controller {
  static targets = ["field", "btn", "chars"];
  static values = { url: String };

  connect() {
    if (this.fieldTarget.innerText.trim() == "") {
      this.fieldTarget.innerText = "Type a note...";
    }
  }

  focus(ev) {
    this.btnTarget.classList.add("show");
    this.charsLeft();

    var range, selection;
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(ev.target);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(ev.target);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  keypress(ev) {
    this.charsLeft(ev);

    if (this.fieldTarget.innerText.length > 250) {
      ev.preventDefault();
    }
  }

  paste(ev) {
    ev.preventDefault(); // Prevent the default paste action

    // Get clipboard data
    let paste = (ev.clipboardData || window.clipboardData).getData("text");

    // Limit the pasted content
    let currentText = this.fieldTarget.innerText;
    let textToPaste = paste.slice(0, 250 - currentText.length);

    // Insert the textToPaste into the contenteditable div
    this.fieldTarget.innerText = currentText + textToPaste;

    this.charsLeft();

    // put cursor at end
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(this.fieldTarget);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  charsLeft() {
    const left = 250 - this.fieldTarget.innerText.length;
    this.charsTarget.innerText = `${left} chars left`;
  }

  async submit(ev) {
    console.log("blur");

    const resp = await put(this.urlValue, {
      body: JSON.stringify({
        note: { body: this.fieldTarget.innerText.trim() },
      }),
    });

    if (resp.ok) {
      this.blur();
    }
  }

  blur(ev) {
    this.btnTarget.classList.remove("show");
  }
}
