/**
 * Creates an iOS style action sheet menu
 * Mobile and desktop compatible
 * Styles set in external stylesheet
 *
 * @param config -  object whose properties need to be set
 *
 * @object menu - array of menu objects to display with the following properties
 * @param title - required - Text to display
 * @param cssClass - optional - additional css classes to add to menu item class list
 * @param onClick - optional - callback when menu option clicked
 * @param dismissOnClick - optional - boolean indicates if the menu option should close menu, such as NO in a yes no option
 * @param hideFast - optional - boolean indicates if menu should be immediated removed. required if callback contains instantiation of second menu due to css transitions
 */
class ActionSheet {
  constructor(config) {
    /**
     * @param menu - array of menu objects
     * @param cancelButton - boolean indicates if cancel button should be displayed
     * @param title - optional title string to display above menu
     * @param text - optional text string to display above menu beneath title.
     */
    if (config) {
      this.menu = config.menu;
      this.cancel = config.cancelButton;
      this.title = config.title;
      this.text = config.text;
    }

    this.defaults = {
      yes_no: {
        title: "Are you sure?",
      },
    };
  }

  render() {
    this._setupSheet()
      .then((div) => {
        // setup menu
        let group = this._setupMenu(this.title);

        // create menu
        this.menu.forEach((el) => {
          group = this._menuOption(div, group, el);
        });
        div.append(group);

        // add cancel if specified
        if (this.cancel) {
          group = this._setupMenu();
          group = this._menuOption(div, group, {
            title: "Cancel",
            dismissOnClick: true,
          });
          div.append(group);
        }

        this._finalizeSheet(div);
      })
      .catch((div) => {
        this.hide(div);
      });
  }

  yesNoMenu(callback) {
    if (!callback) {
      console.error("Callback missing for ActionSheet yesNoMenu");
      return;
    }

    // need to first define the number of sections for height
    this.menu = { length: 3 };
    this._setupSheet()
      .then((div) => {
        let group = this._setupMenu(
          this.title ? this.title : this.defaults.yes_no.title
        );
        group = this._menuOption(div, group, {
          title: "Yes",
          onClick: callback,
        });
        group = this._menuOption(div, group, {
          title: "No",
          dismissOnClick: true,
        });
        div.append(group);

        this._finalizeSheet(div);
      })
      .catch((div) => {
        this.hide(div);
      });
  }

  hide(div) {
    // using this and not menu count to account for titles
    const countBlocks = document.querySelectorAll(
      "#weActionSheet .as-option"
    ).length;
    div.style.bottom = `-${60 * countBlocks}px`;
    div.addEventListener("transitionend", () => {
      this.remove(div);
    });
  }

  remove(div) {
    div.remove();
    if (this._overlay) this._overlay.remove();
  }

  _setupSheet() {
    return new Promise((resolve, reject) => {
      let div = document.getElementById("weActionSheet");
      if (div) {
        reject(div);
      } else {
        div = document.createElement("div");
        div.id = "weActionSheet";
        div.style.bottom = `-${60 * this.menu.length}px`;

        // overlay
        this._overlay = document.createElement("div");
        this._overlay.id = "weActionSheetOverlay";
        document.body.append(this._overlay);

        resolve(div);
      }
    });
  }

  _setupMenu(title) {
    let group = document.createElement("div");
    group.className = "as-group";

    if (title || this.text) {
      const headerDiv = document.createElement("div");
      headerDiv.className = "as-option as-title-option";

      if (title) {
        const titleDiv = document.createElement("div");
        titleDiv.className = "as-title";
        titleDiv.innerText = title;
        headerDiv.append(titleDiv);

        if (this.text) {
          const textDiv = document.createElement("div");
          textDiv.className = "as-text";
          textDiv.innerHTML = this.text;
          headerDiv.append(textDiv);
        }
      }

      group.append(headerDiv);
    }

    return group;
  }

  _menuOption(div, group, el) {
    const option = document.createElement("div");
    option.classList.add("as-option");

    if (el.cssClass) {
      option.classList.add(el.cssClass);
    }

    if (el.onClick) {
      option.addEventListener("click", (ev) => {
        el.hideFast ? this.remove(div) : this.hide(div);
        el.onClick.call(ev);
      });
    }

    if (el.dismissOnClick) {
      option.addEventListener("click", (ev) => {
        this.hide(div);
      });
    }

    option.innerText = el.title;
    group.append(option);

    return group;
  }

  _finalizeSheet(div) {
    document.body.append(div);
    div.style.bottom = "0px";
  }
}

module.exports = ActionSheet;
