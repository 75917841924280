import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";

// Connects to data-controller="bs-modal"
export default class extends Controller {
  static values = { submitMsg: String };

  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false,
    });

    this.element.addEventListener("shown.bs.modal", (event) => {
      const af = this.element.querySelector("[autofocus]");
      if (af) af.focus();
    });

    this.modal.show();
  }

  submitEnd(ev) {
    const notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });

    if (ev.detail.formSubmission.result.success) {
      this.modal.hide();

      notyf.success({
        message: this.submitMsgValue || "Success!",
        dismissible: true,
      });
    } else {
      ev.detail.fetchResponse.response.text().then((body) => {
        const errors = JSON.parse(body).errors;

        notyf.error({
          message: errors,
          dismissible: true,
        });
      });
    }
  }

  disconnect() {
    this.modal.hide();
  }
}
