import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dropdown-select"
export default class extends Controller {
  static targets = ["field", "label"];

  connect() {}

  selected(ev) {
    ev.preventDefault();

    this.fieldTarget.value = ev.params.value;
    this.labelTarget.innerText = ev.params.label;
  }
}
