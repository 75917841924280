import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="welcome-ai"
export default class extends Controller {
  static targets = ["naming", "checkbox", "label", "progress"];
  static values = { url: String, aiUrl: String };

  connect() {}

  submit(ev) {
    ev.preventDefault();

    fetch(this.urlValue, {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify({
        professional: {
          ai_naming: this.checkboxTarget.checked,
        },
      }),
    })
      .then(() => {
        this.startAi();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  startAi() {
    fetch(this.aiUrlValue, {
      method: "POST",
      headers: jsonHeaders(),
    })
      .then(() => {
        this.namingTarget.style.display = "none";
        this.progressTarget.classList.remove("d-none");
        localStorage.setItem("glowScreen", "true");
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
