import { Controller } from "@hotwired/stimulus";
import { visitWithCallback, jsonHeaders } from "../utils";

// Connects to data-controller="page"
export default class extends Controller {
  static targets = ["chromeExtensionCTA", "chromeExtensionMetaTag"];
  static values = { intercomUrl: String };

  connect() {
    window.setTimeout(() => {
      this.initCheck();
    }, 3000);

    this.initObserver();
  }

  initObserver() {
    // Select the meta tag using a selector.
    if (this.hasChromeExtensionMetaTagTarget) {
      // Create a new MutationObserver instance.
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "content"
          ) {
            const newValue =
              this.chromeExtensionMetaTagTarget.getAttribute("content");

            // Check if it changed to "true".
            if (newValue === "true") {
              this.chromeExtensionCTATargets.forEach((el) =>
                el.classList.remove("show")
              );
              this.updateIntercom();
            }
          }
        });
      });

      // Start observing the meta tag for changes in its attributes.
      observer.observe(this.chromeExtensionMetaTagTarget, {
        attributes: true, // Observe attribute changes.
        attributeFilter: ["content"], // Only observe changes to the 'content' attribute.
      });
    }
  }

  initCheck() {
    function isChrome() {
      return (
        /Chrome/.test(navigator.userAgent) &&
        !/Edg|OPR/.test(navigator.userAgent)
      );
    }

    // first check for glow tour
    if (localStorage.getItem("glowScreen") == "true") {
      if (isChrome()) {
        this.checkChromeExtension();
      } else {
        this.recommendChrome();
      }
    }
  }

  checkChromeExtension() {
    if (
      this.hasChromeExtensionCTATarget &&
      this.chromeExtensionMetaTagTarget?.content == "true"
    ) {
      this.chromeExtensionCTATargets.forEach((el) => el.remove());
      this.updateIntercom();
    }

    if (
      this.hasChromeExtensionCTATarget &&
      this.chromeExtensionMetaTagTarget?.content == "false"
    ) {
      this.chromeExtensionCTATargets.forEach((el) => el.classList.add("show"));

      if (localStorage.getItem("chromeChecks") != "true") {
        localStorage.setItem("chromeChecks", "true");
        visitWithCallback("/welcome/chrome_extension_needed", () => {
          var modal = new bootstrap.Modal(
            document.getElementById("chromeExtensionModal")
          );
          modal.show();
        });
      }
    }
  }

  recommendChrome() {
    this.chromeExtensionCTATargets.forEach((el) => el.classList.add("show"));

    if (localStorage.getItem("chromeChecks") != "true") {
      localStorage.setItem("chromeChecks", "true");
      visitWithCallback("/welcome/chrome_recommended", () => {
        var modal = new bootstrap.Modal(
          document.getElementById("chromeRecommendedModal")
        );
        modal.show();
      });
    }
  }

  updateIntercom() {
    if (this.intercomUrlValue) {
      fetch(this.intercomUrlValue, {
        method: "put",
        headers: jsonHeaders(),
        body: JSON.stringify({
          attributes: {
            "Wecora Capture": true,
          },
        }),
      });
    }
  }
}
