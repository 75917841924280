import { Controller } from "@hotwired/stimulus";
import { show } from "@intercom/messenger-js-sdk";

// Connects to data-controller="home"
export default class extends Controller {
  connect() {}

  help(ev) {
    ev.preventDefault();
    show();
  }
}
