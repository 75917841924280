import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="idea"
export default class extends Controller {
  static targets = [
    "media",
    "headerInfo",
    "accordion",
    "details",
    "permissionOption",
  ];
  static values = { itemUrl: String };

  connect() {}

  detailsLoaded(ev) {
    const accordions = document.querySelectorAll(".accordion");
    accordions.forEach((accordion) => {
      accordion?.addEventListener("shown.bs.collapse", (ev) => {
        this.scrollIntoView(ev);
      });
    });

    this.accordionTargets.forEach((el) => el.classList.remove("d-none"));
    this.detailsTarget.classList.remove("d-none");
  }

  scrollIntoView(ev) {
    const outerDiv = document.getElementById("itemInfoContent");
    const innerDiv = ev.target.parentNode;

    const innerDivRect = innerDiv.getBoundingClientRect();
    const outerDivRect = outerDiv.getBoundingClientRect();

    if (innerDivRect.bottom > outerDivRect.bottom) {
      innerDiv.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }
}
