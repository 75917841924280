import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="board-permissions"
export default class extends Controller {
  static targets = ["option"];
  static values = { url: String };

  connect() {}

  change(ev) {
    ev.preventDefault();

    // generate share link
    const data = {};
    this.optionTargets.forEach((el) => {
      data[el.dataset.name] = el.checked;
    });

    console.log(data);

    fetch(this.urlValue, {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify({ board: { view_permissions: data } }),
    })
      .then((resp) => {
        if (!resp.ok) {
          window.notyf.error(resp.errors);
        }
      })
      .catch((err) => console.log(err));
  }
}
