import { Controller } from "@hotwired/stimulus";
import ActionSheet from "../modules/action_sheet";
import { jsonHeaders } from "../utils";

// Connects to data-controller="board-transfer"
export default class extends Controller {
  static values = { projectUrl: String };

  connect() {}

  transfer(ev) {
    ev.preventDefault();

    const url = this.element.action;
    const name = ev.currentTarget.innerText;
    const id = ev.params.toId;

    const as = new ActionSheet({
      title: "Transfer Board Ownership",
      text: `Are you sure you want to transfer ownership to <strong>${name}</strong>? <br/>This will give them full control of this board.`,
    });

    as.yesNoMenu(() => {
      window.notyf.success({
        message: "Transfering Board...",
        dismissible: true,
      });

      const body = {
        board: {
          owner_id: id,
        },
      };

      fetch(url + ".json", {
        method: "PUT",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => resp.text())
        .then((html) => {
          Turbo.renderStreamMessage(html);

          window.notyf.dismissAll();
          window.notyf.success({
            message: "Board Transfered!",
            dismissible: true,
          });

          document.location.href = this.projectUrlValue;
        })
        .catch((err) => {
          window.notyf.dismissAll();
          window.notyf.error({
            message:
              "There was a problem transfering this board.  Please contact support.",
            dismissible: true,
          });
        });
    });
  }
}
