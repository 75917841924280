import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="gallery-items"
export default class extends Controller {
  static targets = ["items", "item"];
  static values = {
    url: String,
  };

  connect() {
    if (!this.hasItemsTarget) {
      return;
    }

    Sortable.create(this.itemsTarget, {
      group: {
        name: "shared",
        pull: "clone",
      },
      animation: 300,
      filter: ".no-drag",
      sort: false,
      onStart: (evt) => {
        document.body.classList.toggle("sortable-active");
        this.itemTargets.forEach((item) => {
          item.classList.remove("active");
        });
      },
      onMove: (evt) => {
        if (evt.to.classList.contains("no-drop")) {
          return false;
        }
      },
      onEnd: (evt) => {
        document.body.classList.toggle("sortable-active");

        const trigger = new CustomEvent("sortable-complete");
        window.dispatchEvent(trigger);

        if (evt.to === evt.from) {
          return false;
        }

        const searchResult = evt.item.dataset.searchResult;
        const itemId = evt.item.dataset.itemId;
        const itemFingerprint = evt.item.dataset.itemFingerprint;
        const url = evt.to.dataset.ideaUrl;
        const position = evt.newIndex;

        evt.item.style.opacity = 0.35;
        evt.item.classList.add("rounded-4");
        evt.item.classList.add("sortable-dropped");
        evt.item.classList.add("sortable-ghost");

        if (searchResult) {
          const body = {
            item: {
              name: evt.item.dataset.name,
              media_url: evt.item.dataset.imageSrc,
              source: evt.item.dataset.source,
              bucket: evt.item.dataset.bucket,
            },
          };

          this.createItem(body)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((json) => {
                  evt.item.id = `dropped_item_${json.fingerprint}`; // use this for replacing later

                  this.createIdea(url, json.id, evt.newIndex - 1);
                });
              } else {
                resp.json().then((json) => {
                  evt.item.remove();
                  console.error("create item error", json);
                  window.notyf.error(json.errors);
                });
              }
            })
            .catch((error) => {
              evt.item.remove();
              console.error("error", error);
              window.notyf.error(error.errors);
            });
        } else {
          if (url && itemId) {
            evt.item.style.opacity = 0.15;
            evt.item.id = `dropped_item_${itemFingerprint}`; // use this for replacing later

            this.createIdea(url, itemId, position);
          }
        }
      },
    });
  }

  add(ev) {
    ev.preventDefault();

    const container = document.querySelector(".tile_container_wrapper");
    const url = container.dataset.containerIdeasUrlValue;
    const itemId = ev.params.itemId;
    const itemFingerprint = ev.params.itemFingerprint;
    const tiles = container.querySelector(".tiles");

    if (itemId) {
      // create div to replace...
      const div = document.createElement("div");
      div.style.opacity = 0.15;
      div.className = "sortable-ghost";
      div.id = `dropped_item_${itemFingerprint}`; // use this for replacing later
      tiles.prepend(div);

      this.createIdea(url, itemId, 0);
    } else {
      // find the grid item
      const gridItem = ev.currentTarget.closest(".grid-item");

      const body = {
        item: {
          name: gridItem.dataset.name,
          media_url: gridItem.dataset.imageSrc,
          source: gridItem.dataset.source,
          bucket: "pinterest",
        },
      };

      this.createItem(body)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((json) => {
              const container = document.querySelector(
                ".tile_container_wrapper"
              );
              const url = container.dataset.containerIdeasUrlValue;

              const div = document.createElement("div");
              div.style.opacity = 0.15;
              div.className = "sortable-ghost";
              div.id = `dropped_item_${json.fingerprint}`; // use this for replacing later
              tiles.prepend(div);

              this.createIdea(url, json.id, 0);
            });
          } else {
            console.log("resp", resp);
            resp.json().then((json) => {
              window.notyf.error(json.errors);
            });
          }
        })
        .catch((error) => {
          window.notyf.error(error.errors);
        });
    }
  }

  createItem(body) {
    return new Promise((resolve, reject) => {
      fetch(this.urlValue + ".json", {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createIdea(url, itemId, position) {
    Turbo.fetch(url, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify({
        idea: { item_id: itemId, position: position },
      }),
    })
      .then((resp) => resp.text())
      .then((html) => {
        // evt.item.remove();
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }
}
