import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fix-backdrop"
export default class extends Controller {
  connect() {
    console.log("fix-backdrop#connect");
    this.element.addEventListener("shown.bs.modal", () => {
      // handle modal backdrop
      console.log("shown");
      const backdrop = document.querySelector(".modal-backdrop.show");
      this.element.after(backdrop);
    });
  }
}
