import { Controller } from "@hotwired/stimulus";
import ActionSheet from "../modules/action_sheet";
import { jsonHeaders } from "../utils";

// Connects to data-controller="company"
export default class extends Controller {
  connect() {}

  makeAdmin(ev) {
    ev.preventDefault();

    const as = new ActionSheet({
      title: "Tranfer Admin Control",
      text: ev.params.msg,
    });

    as.yesNoMenu(() => {
      window.notyf.open({
        type: "warning",
        message: "Transferring",
      });

      fetch(ev.target.href, {
        method: "POST",
        headers: jsonHeaders(),
      }).then((resp) => {
        if (resp.ok) {
          window.notyf.success("Transferred!");
          resp.json().then((json) => {
            location.href = json.url;
          });
        }
      });
    });
  }
}
