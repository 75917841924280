import { Controller } from "@hotwired/stimulus";
import { boot } from "@intercom/messenger-js-sdk";

// Connects to data-controller="public-board"
export default class extends Controller {
  static targets = [
    "viewGrid",
    "viewDetailed",
    "viewBudget",
    "viewStream",
    "canvas",
  ];

  connect() {
    let view = localStorage.getItem("publicBoardView") || "public-grid";
    if (view) {
      this.viewDetailedTarget.classList.remove("active");
      this.viewStreamTarget.classList.remove("active");
      this.viewGridTarget.classList.remove("active");

      if (this.hasViewBudgetTarget) {
        this.viewBudgetTarget?.classList.remove("active");
      } else {
        // this prevents someone who was looking at a budget and then clicks to a non-budget link from seeing a budget
        view = "public-grid";
      }

      this.canvasTarget.dataset.view = view;
      document.body.dataset.view = view;

      switch (view) {
        case "public-grid":
          this.viewGridTarget.classList.add("active");
          break;
        case "public-detailed":
          this.viewDetailedTarget.classList.add("active");
          break;
        case "public-stream":
          this.viewStreamTarget.classList.add("active");
          break;
        case "public-budget":
          if (this.hasViewBudgetTarget) {
            this.viewBudgetTarget.classList.add("active");
          } else {
            this.viewGridTarget.classList.add("active");
          }
          break;

        default:
          this.viewGridTarget.classList.add("active");
          break;
      }
    } else {
      this.viewGridTarget.classList.add("active");
    }
  }

  changeView(ev) {
    ev.preventDefault();

    document.body.dataset.view = ev.params.view;
    this.canvasTarget.dataset.view = ev.params.view;

    localStorage.setItem("publicBoardView", ev.params.view);

    document.body.classList.remove("info-panel-active");

    this.viewStreamTarget.classList.remove("active");
    this.viewDetailedTarget.classList.remove("active");
    this.viewGridTarget.classList.remove("active");

    if (this.hasViewBudgetTarget) {
      this.viewBudgetTarget?.classList.remove("active");
    }

    ev.currentTarget.classList.add("active");
  }
}
