import { Controller } from "@hotwired/stimulus";
import { show } from "@intercom/messenger-js-sdk";

// Connects to data-controller="utilities"
export default class extends Controller {
  static values = { vp: String };

  connect() {
    const darkMode = localStorage.getItem("darkMode");
    this.handleDarkMode(darkMode == "on");

    if (window.innerWidth < 992) {
      // close all inner dropdowns when parent is closed
      document.querySelectorAll(".dropdown").forEach(function (everydropdown) {
        everydropdown.addEventListener("hidden.bs.dropdown", function () {
          // after dropdown is hidden, then find all submenus
          this.querySelectorAll(".submenu").forEach(function (everysubmenu) {
            // hide every submenu as well
            everysubmenu.style.display = "none";
          });
        });
      });

      document.querySelectorAll(".dropdown-menu a").forEach(function (element) {
        element.addEventListener("click", function (e) {
          let nextEl = this.nextElementSibling;
          if (nextEl && nextEl.classList.contains("submenu")) {
            // prevent opening link if link needs to open dropdown
            e.preventDefault();
            if (nextEl.style.display == "block") {
              nextEl.style.display = "none";
            } else {
              nextEl.style.display = "block";
            }
          }
        });
      });
    }

    // ip controls for images
    document.addEventListener("contextmenu", function (event) {
      if (event.target.classList.contains("norc")) {
        event.preventDefault();
      }
    });
  }

  darkModeToggle(ev) {
    ev.preventDefault();
    this.handleDarkMode(!document.body.classList.contains("dark-mode"));
  }

  handleDarkMode(state) {
    if (!state) {
      document.body.classList.remove("dark-mode");
      document.getElementsByTagName("html")[0].dataset.bsTheme = "light";
      localStorage.setItem("darkMode", "off");
    } else {
      document.body.classList.add("dark-mode");
      document.getElementsByTagName("html")[0].dataset.bsTheme = "dark";
      localStorage.setItem("darkMode", "on");
    }
  }

  click(event) {
    event.preventDefault();

    let link = event.currentTarget;
    let url = new URL(link.href);

    url.searchParams.append("vp", this.vpValue);

    // Update the Turbo Frame src or navigate with the updated URL
    link.href = url.toString();

    Turbo.visit(link.href, { frame: event.currentTarget.dataset.turboFrame });
  }

  // helper utility to use when turbo_frame is false so data-turbo-submits-with wont work
  submit(ev) {
    ev.target.value = ev.params.msg;
    setTimeout(() => {
      ev.target.disabled = true;
    });
  }

  help(ev) {
    ev.preventDefault();
    show();
  }
}
