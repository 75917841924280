import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="task"
export default class extends Controller {
  connect() {}

  async checkbox(ev) {
    await put(this.element.action, {
      body: {
        task: {
          completed: !ev.params.complete,
        },
      },
      responseKind: "turbo-stream",
    });
  }
}
