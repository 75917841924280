import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Notyf } from "notyf";
import { headers, csrfToken } from "../utils";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["addblock"];
  static values = {
    url: String,
    objectUrl: String,
  };

  connect() {
    if (this.dropzone) {
      return;
    }

    const clickable = this.element.querySelector(".add-block");

    const dropzoneConfig = {
      url: this.urlValue,
      method: "post",
      paramName: "item[media]",
      clickable: clickable,
      acceptedFiles:
        "image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      maxFilesize: 25,
      maxFiles: 20,
      previewsContainer: ".dz-preview",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    };

    this.dropzone = new Dropzone(
      this.element.closest(".tile_container"),
      dropzoneConfig
    );

    this.dropzone.on("dragenter", (e) => {
      if (document.body.classList.contains("sortable-active")) {
        this.dropzone.element.classList.remove("dz-drag-hover");
        this.dropzone.disable();
      }
    });

    this.dropzone.on("addedfile", (file) => {
      file.previewElement.id = `upload_${file.upload.uuid}`; // use this for replacing later
      this.element.querySelector(".tiles").prepend(file.previewElement);

      if (this.hasAddblockTarget) {
        this.addblockTarget.remove();
      }
    });

    this.dropzone.on("error", function (file, error) {
      console.log("error", { error, file });

      this.removeFile(file);

      const notyfError = new Notyf({
        duration: 4000,
        position: { x: "right", y: "top" },
      });

      function isJSON(str) {
        try {
          return JSON.parse(str) && !!str;
        } catch (e) {
          return false;
        }
      }

      if (isJSON(error)) {
        const errors = JSON.parse(error);
        console.log("errors", errors);
        notyfError.error({
          message: `Error Uploading ${file.name} - ${errors.errors}`,
          dismissible: true,
        });
      } else {
        notyfError.error({
          message: `Error Uploading ${file.name} - ${error}`,
          dismissible: true,
        });
      }
    });

    this.dropzone.on("complete", (file) => {
      this.dropzone.removeFile(file);
    });

    this.dropzone.on("success", (file, data) => {
      this.createIdea(file, data);
    });

    window.boardDropZone ??= this.dropzone;
  }

  click(ev) {
    ev.preventDefault();
    this.dropzone.hiddenFileInput.click();
  }

  createIdea(file, item) {
    document.getElementById(
      `upload_${file.upload.uuid}`
    ).id = `dropped_item_${item.fingerprint}`;

    fetch(this.objectUrlValue, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify({ idea: { item_id: item.id, position: 0 } }),
    })
      .then((resp) => resp.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .catch((error) => {
        console.error("error", error);
      });
  }

  reenable() {
    const res = this.dropzone?.enable();
    // console.log("reenabling dz", res);
  }
}
