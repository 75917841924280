import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = ["items", "item", "apps", "app", "showMore", "hiddenList"];

  connect() {
    if (this.hasAppsTarget) {
      this.handleResize();
    }
  }

  active() {
    document.getElementById("gallery").innerHTML = "";
    document.body.classList.add("gallery-active");
  }

  close(ev) {
    document.getElementById("gallery").innerHTML = "";
    document.body.classList.remove("gallery-active");
  }

  upload(ev) {
    ev.preventDefault();

    window.boardDropZone?.element.click();
  }

  toggle(ev) {
    ev.preventDefault();

    const state = ev.currentTarget.classList.contains("active");

    this.itemTargets.forEach((item) => {
      item.classList.remove("active");
    });

    if (!state) {
      ev.currentTarget.classList.toggle("active");
    }
  }

  handleResize() {
    const container = this.appsTarget;
    const showMore = this.showMoreTarget;
    const hiddenList = this.hiddenListTarget;
    const children = this.appTargets;

    function updateVisibility() {
      let containerHeight = container.clientHeight;
      let childHeight = children[0].clientHeight;
      let maxVisible = Math.floor(containerHeight / childHeight);

      hiddenList.innerHTML = "";

      children.forEach((child, index) => {
        if (index < maxVisible) {
          child.style.display = "block";
        } else {
          child.style.display = "none";
          const clone = child.cloneNode(true);
          clone.style.display = "block";
          const li = document.createElement("li");
          li.append(clone);
          hiddenList.appendChild(li);
        }
      });

      if (hiddenList.children.length > 0) {
        showMore.classList.remove("d-none");
      } else {
        showMore.classList.add("d-none");
      }
    }
    updateVisibility();
    window.addEventListener("resize", updateVisibility);
  }
}
