import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="member"
export default class extends Controller {
  connect() {}

  permission(ev) {
    const url = ev.currentTarget.href;

    put(url);
  }
}
