import { Controller } from "@hotwired/stimulus";
import VanillaContextMenu from "vanilla-context-menu";
import ActionSheet from "../modules/action_sheet";
import { headers, jsonHeaders, csrfToken } from "../utils";

// Connects to data-controller="item"
export default class extends Controller {
  static targets = [
    "image",
    "file",
    "project",
    "btn",
    "url",
    "media",
    "zoomModal",
  ];
  static values = {
    id: Number,
    projectId: String,
    libraryTarget: String,
    url: String,
    image: String,
    canEdit: Boolean,
    deletable: Boolean,
    shareUrl: String,
    exportUrl: String,
    open: Boolean,
    inUse: Boolean,
    isImage: Boolean,
    mediaUrl: String,
  };

  connect() {
    if (this.element.classList.contains("modal")) {
      this.modal = new bootstrap.Modal(this.element, {
        keyboard: false,
      });

      this.modal.show();
    }

    if (this.element.classList.contains("grid-item")) this.contextMenu();

    if (this.openValue) {
      this.show();
    }
  }

  show() {
    document
      .querySelectorAll(".item")
      .forEach((el) => el.classList.remove("selected"));
    this.element.classList.add("selected");

    Turbo.visit(this.urlValue, { frame: "info_panel" });

    document.body.classList.add("info-panel-active");
  }

  contextMenu() {
    const menuItems = [
      {
        label: "Copy",
        iconClass: "fa-regular fa-copy",
        callback: () => {
          const url = `/items/${this.idValue}/copy`;

          fetch(url, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((err) => {
              window.notyf.errors(err);
            });
        },
      },
    ];

    if (this.canEditValue) {
      menuItems.push({
        label: "Move",
        iconClass: "fa-regular fa-file-export",
        callback: (ev) => {
          ev.preventDefault();

          const url = `/items/${this.idValue}/move`;

          fetch(url, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((err) => {
              window.notyf.errors(err);
            });
        },
      });
    }

    menuItems.push(
      "hr",
      {
        label: "Share",
        iconClass: "fa-regular fa-share",
        callback: () => {
          fetch(this.shareUrlValue, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      },
      "hr",
      {
        label: "Download",
        iconClass: "fa-regular fa-download",
        callback: () => {
          this.download();
        },
      }
    );

    if (this.isImageValue) {
      menuItems.push(
        {
          label: "Tear Sheet",
          iconClass: "fa-regular fa-file-pdf",
          callback: () => {
            fetch(this.exportUrlValue, {
              headers: headers(),
            })
              .then((resp) => resp.text())
              .then((html) => Turbo.renderStreamMessage(html));
          },
        },
        "hr"
      );
    }

    menuItems.push({
      label: "Add To Board",
      iconClass: "fa-regular fa-grid",
      callback: () => {
        const url = `/items/add_to_board`;
        const body = {
          project_id: this.projectIdValue,
          item_ids: [this.idValue],
        };

        fetch(url, {
          method: "POST",
          headers: headers(),
          body: JSON.stringify(body),
        })
          .then((resp) => resp.text())
          .then((html) => Turbo.renderStreamMessage(html))
          .catch((err) => {
            window.notyf.errors(err);
          });
      },
    });

    if (this.canEditValue) {
      menuItems.push(
        "hr",
        {
          label: "Assign Labels",
          iconClass: "fa-regular fa-tags",
          callback: () => {
            const div = document.createElement("div");
            div.dataset.itemId = this.idValue;
            div.dataset.image = this.imageValue;

            const modal = bootstrap.Modal.getInstance("#assignLabelsModal");
            modal.show(div);
          },
        },
        "hr",
        {
          label: this.deletableValue ? "Delete" : "Remove",
          callback: () => {
            this.delete();
          },
          iconClass: "fa fa-trash", // this only works if you have FontAwesome icons
        }
      );
    }

    new VanillaContextMenu({
      scope: this.element,
      customThemeClass: "cm-bootstrap",
      transitionDuration: 0,
      menuItems: menuItems,
    });
  }

  thumbnailClick(ev) {
    this.imageTargets.forEach((el) => (el.src = ev.params.src));

    if (ev.params.media) {
      this.mediaTarget.classList.add("d-none");
    } else {
      this.mediaTarget.classList.remove("d-none");
    }
  }

  async download() {
    try {
      const response = await fetch(this.mediaUrlValue);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const filename = this.getFilename(this.mediaUrlValue, blob.type);

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }

  getFilename(url, mimeType) {
    // Extract the filename and remove any URL encoding
    const urlParts = url.split("/");
    let filename = urlParts[urlParts.length - 1];
    filename = decodeURIComponent(filename);

    // Remove any existing extension
    filename = filename.split(".")[0];

    // Map common MIME types to file extensions
    const mimeToExt = {
      "image/jpeg": ".jpg",
      "image/png": ".png",
      "image/gif": ".gif",
      "image/webp": ".webp",
      "image/svg+xml": ".svg",
      "application/pdf": ".pdf",
      "application/json": ".json",
      "text/plain": ".txt",
      "text/html": ".html",
      "text/css": ".css",
      "text/javascript": ".js",
      "application/xml": ".xml",
      "application/zip": ".zip",
      "audio/mpeg": ".mp3",
      "audio/wav": ".wav",
      "video/mp4": ".mp4",
      "video/webm": ".webm",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        ".docx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        ".xlsx",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        ".pptx",
    };

    // Get the extension from the MIME type, or use a generic extension if unknown
    const extension =
      mimeToExt[mimeType] || this.getExtensionFromMimeType(mimeType);

    return filename + extension;
  }

  getExtensionFromMimeType(mimeType) {
    // For unknown MIME types, create an extension from the subtype
    if (mimeType) {
      const [type, subtype] = mimeType.split("/");
      if (subtype) {
        // Handle cases where subtype might contain additional parameters
        const cleanSubtype = subtype.split(";")[0];
        return "." + cleanSubtype;
      }
    }
    return ".bin"; // Default extension for unknown types
  }

  deleteAttachment(ev) {
    console.log("deleteAttachment", ev.currentTarget);
    ev.preventDefault();
    ev.stopPropagation();

    const el = ev.currentTarget;

    fetch(ev.params.url, {
      method: "DELETE",
      headers: jsonHeaders(),
    })
      .then(() => {
        el.closest(".grid-item").remove();
      })
      .catch((err) => console.log(err));
  }

  upload(ev) {
    ev.preventDefault();

    this.btnTarget.disabled = true;
    this.btnTarget.innerText = "Uploading...";

    const action = ev.currentTarget.action;
    const files = Array.from(this.fileTarget.files);
    let index = 0;
    files.forEach((file) => {
      window.notyf.success({
        message: `Uploading ${file.name}...`,
        dismissible: true,
      });

      const formData = new FormData(); // Create a new FormData object
      formData.append("item[media]", file);
      formData.append("project_id", this.projectTarget.value);

      fetch(action, {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken(),
          Accept: "text/vnd.turbo-stream.html",
        },
        body: formData,
      })
        .then((resp) => {
          if (resp.ok) {
            resp.text().then((html) => {
              Turbo.renderStreamMessage(html);
            });
          } else {
            resp.text().then((body) => {
              const errors = JSON.parse(body).errors;

              window.notyf.error({
                message: `Error uploading ${file.name} - ${errors}`,
                dismissible: true,
              });
            });
          }

          index++;
          if (index >= files.length) {
            console.log("close");
            this.modal.hide();
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    });
  }

  removeLabel(ev) {
    ev.currentTarget.classList.remove("fa-close");
    ev.currentTarget.classList.add("fa-spin", "fa-spinner");

    fetch(ev.params.url, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.json())
      .then(() => {
        document.getElementById("details").reload();
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  delete(ev) {
    ev?.preventDefault();

    let as;
    if (this.deletableValue) {
      as = new ActionSheet({
        title: "Delete Item",
        text: `Are you sure you want to delete this item? This action cannot be undone.`,
      });
    } else {
      if (this.inUseValue) {
        as = new ActionSheet({
          title: "Item In Use",
          text: `This item is in use on a board. Removing it will also eliminate it from the boards. Are you sure you want to remove this item from the library?`,
        });
      } else {
        as = new ActionSheet({
          title: "Remove Item",
          text: `Are you sure you want to remove this item from the library?`,
        });
      }
    }

    as.yesNoMenu(() => {
      this.destroy();
    });
  }

  destroy() {
    fetch(this.urlValue + ".json", {
      method: "DELETE",
      headers: jsonHeaders(),
    })
      .then((resp) => {
        if (resp.ok) {
          document.body.classList.remove("info-panel-active");

          document.getElementById(this.libraryTargetValue)?.remove();

          if (document.querySelectorAll("#items .item").length == 0) {
            document.getElementById("items").reload();
          }

          window.notyf.success({
            message: `Item successfully ${
              this.deletableValue ? "deleted" : "removed"
            }`,
            dismissible: true,
          });
        } else {
          resp.json().then((body) => {
            console.log("body", body);
            window.notyf.error({
              message: body.errors,
              dismissible: true,
            });
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  restore(ev) {
    ev.preventDefault();

    const body = {
      item: {
        archived: false,
      },
    };

    fetch(this.urlValue, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.text())
      .then((html) => {
        document.getElementById(this.libraryTargetValue)?.remove();

        window.notyf.success({
          message: `Item restored`,
          dismissible: true,
        });
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  zoom(ev) {
    const modal = new bootstrap.Modal(this.zoomModalTarget);
    modal.show();
  }
}
