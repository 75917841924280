import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="list"
export default class extends Controller {
  static targets = ["link"];

  connect() {}

  clicked(ev) {
    this.linkTargets.forEach((link) => {
      link.classList.remove("active");
    });

    ev.currentTarget.classList.add("active");
  }
}
