import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="item-present"
export default class extends Controller {
  static targets = ["option"];

  connect() {}

  present(ev) {
    ev.preventDefault();

    const canvas = document.getElementById("canvas");
    canvas.dataset.view = "presentation-grid";
    document.body.dataset.view = "presentation-grid";

    document.body.classList.remove("info-panel-active");

    // shut everything off first
    Object.keys(canvas.dataset).forEach((key) => {
      if (key.startsWith("vp")) {
        delete canvas.dataset[key];
      }
    });

    // document
    //   .querySelectorAll(".vp-show")
    //   .forEach((el) => el.classList.remove("vp-show"));

    // now turn on selections

    this.optionTargets.forEach((el) => {
      if (el.checked) {
        canvas.setAttribute(`data-${el.dataset.name}`, "");
      }
    });
  }
}
