import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin-company"
export default class extends Controller {
  static values = { url: String };

  connect() {}

  filterPlan(ev) {
    location.href = `${this.urlValue}?plan=${ev.params.plan}`;
  }
}
