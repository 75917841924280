import { Application } from "@hotwired/stimulus";
import { Notyf } from "notyf";

window.notyf = new Notyf({
  duration: 4000,
  position: { x: "right", y: "top" },
  types: [
    {
      type: "info",
      background: "blue",
      icon: false,
    },
    {
      type: "warning",
      background: "orange",
      icon: {
        className: "fa-solid fa-circle-exclamation",
        tagName: "em",
      },
    },
  ],
});

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
