import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="progress-bar"
export default class extends Controller {
  static targets = ["bar"];

  connect() {
    console.log("progress-bar@connect", this.barTarget);
    const obj = this.generateRandomSteps();
    let cumulativeTime = 0;

    obj.forEach((el) => {
      cumulativeTime += el.timer * 500;
      setTimeout(() => {
        this.barTarget.style.width = `${el.step}%`;

        if (el.step == 100) {
          this.checkMetatag();
        }
      }, cumulativeTime);
    });
  }

  generateRandomSteps() {
    const ranges = [
      [0, 20],
      [20, 40],
      [40, 60],
      [60, 80],
      [80, 100],
    ];

    const steps = [];

    ranges.forEach(([min, max], index) => {
      // Generate a random step value within the specified range
      const stepValue = Math.floor(Math.random() * (max - min + 1)) + min;

      // Random timer value between 1 and 5 seconds
      const timerValue = Math.floor(Math.random() * 3) + 1;

      // Add the step and timer to the array
      steps.push({
        step: stepValue,
        timer: timerValue,
      });
    });

    steps.push({
      step: 100,
      timer: 1,
    });

    return steps;
  }

  checkMetatag() {
    // Find the meta element with name="wecora-chrome-extension"
    const metaTag = document.querySelector(
      'meta[name="wecora-chrome-extension"][data-page-target="chromeExtensionMetaTag"]'
    );

    if (metaTag) {
      // Get the content attribute and check if it's "true"
      const isTrue = metaTag.content === "true";

      if (isTrue) {
        console.log("The content is true");
        // Do something if the content is "true"
      } else {
        console.log("The content is false");
        // Do something if the content is not "true"
      }
    } else {
      console.log("Meta tag not found");
    }
  }
}
