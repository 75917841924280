import { Controller } from "@hotwired/stimulus";
import { headers } from "../utils";

export default class extends Controller {
  static values = {
    url: String,
    itemUrl: String,
    listen: Boolean,
    showComments: Boolean,
  };

  connect() {
    if (document.body.classList.contains("sortable-active")) return;

    // TODO: figure out why we have this and if needed
    this.element.addEventListener("turbo:before-fetch-response", () => {
      this.resultHandler();
    });

    document.addEventListener("keydown", this.bindEscape);

    if (this.showCommentsValue) {
      document.getElementById("comment_comment").focus();
    }
  }

  bindEscape(ev) {
    if (ev.key === "Escape") {
      document.body.classList.remove("info-panel-active");
      document.removeEventListener("keydown", this.bindEscape);
      document.getElementById("info_panel").innerHTML = "";
      document
        .querySelectorAll(".item")
        .forEach((el) => el.classList.remove("selected"));
    }
  }

  resultHandler(ev) {
    document.body.classList.add("info-panel-active");
    document.body
      .querySelector("#canvas .overlay")
      ?.addEventListener("click", this.close);
  }

  show(ev) {
    ev.preventDefault();

    // check for presentation view
    const url =
      document.body.dataset.view == "presentation"
        ? this.itemUrlValue
        : this.urlValue;

    fetch(url, {
      method: "GET",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.resultHandler(ev);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  close(ev) {
    document.body.classList.remove("info-panel-active");
    document.removeEventListener("keydown", this.bindEscape);
    document.getElementById("info_panel").innerHTML = "";
    document
      .querySelectorAll(".item")
      .forEach((el) => el.classList.remove("selected"));
  }

  toggleComments(ev) {
    ev.preventDefault();

    if (this.element.classList.contains("comments-active")) {
      this.element.classList.remove("comments-active");
    } else {
      this.showComments();
    }
  }

  showComments() {
    this.element.classList.add("comments-active");

    const lastComment = document
      .getElementById("commentsSection")
      .querySelector(".comment:last-child");

    lastComment?.scrollIntoView();
  }
}
