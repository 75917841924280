import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="items-assign-to-board"
export default class extends Controller {
  static targets = ["btn"];
  static values = { id: Number, projectToken: String };

  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false,
    });

    this.element.addEventListener("show.bs.modal", (ev) => {
      Turbo.visit(
        "/finder?project_only=true&exclude_project=${this.projectTokenValue}",
        { frame: "finder" }
      );
    });
  }

  finderSelected(ev) {
    if (ev.detail.project) {
      this.projectId = ev.detail.project.token;
      this.btnTarget.classList.remove("d-none");
      this.btnTarget.disabled = false;
    } else {
      this.btnTarget.classList.add("d-none");
    }
  }

  copy(ev) {
    ev.preventDefault();

    if (this.projectId) {
      this.btnTarget.disabled = true;

      window.notyf.success({
        message: "Copying...",
        dismissible: true,
      });

      const body = {
        project_id: this.projectId,
      };

      const url = `/items/${this.idValue}/copy.json`;

      fetch(url, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => {
          if (resp.ok) {
            resp
              .json()
              .then((json) => {
                console.log(json);

                window.notyf.dismissAll();
                window.notyf.success(
                  `Copied to <a href="/projects/${json.item.project.token}">${json.item.project.name}</a>`
                );

                document.getElementById("details")?.reload();

                const modal = bootstrap.Modal.getInstance("#bsTurboModal");
                modal.hide();
              })
              .catch((e) => {
                throw Error(e);
              });
          } else {
            resp.json().then((json) => {
              this.btnTarget.disabled = false;
              window.notyf.dismissAll();
              window.notyf.error({
                message: `Error copying item - ${json.errors}`,
                dismissible: true,
              });
            });
          }
        })
        .catch((error) => {
          this.btnTarget.disabled = false;
          window.notyf.dismissAll();
          window.notyf.error({
            message: `Error copying item - ${error}`,
            dismissible: true,
          });
        });
    } else {
      console.log("error - no project selected");
    }
  }
}
