import { Controller } from "@hotwired/stimulus";
import { visitWithCallback } from "../utils";

// Connects to data-controller="welcome"
export default class extends Controller {
  connect() {
    window.setTimeout(() => {
      const frame = document.getElementById("remote_modal");
      if (!frame) {
        return;
      }

      if (localStorage.getItem("glowScreen") != "true") {
        visitWithCallback("/welcome/glow", () => {
          var modal = new bootstrap.Modal(document.getElementById("glowModal"));
          modal.show();
        });
      }
    }, 2500);
  }
}
