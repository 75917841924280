import { Controller } from "@hotwired/stimulus";
import { headers } from "../utils";

// Connects to data-controller="votes"
export default class extends Controller {
  static values = { url: String };
  static targets = ["vote"];

  connect() {}

  vote(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    fetch(this.urlValue, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify({ liked: ev.params.vote }),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((err) => {
        console.log(err);
        window.notyf.error({
          message: "There was a problem with this vote.  Please try again",
          dismissible: true,
        });
      });
  }
}
