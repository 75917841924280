import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="boards"
export default class extends Controller {
  static targets = ["form", "view"];

  connect() {}

  submit() {
    this.formTarget.requestSubmit();
  }

  changeView(ev) {
    this.viewTarget.value = ev.params.view;
    this.submit();
  }
}
