import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Notyf } from "notyf";
import { csrfToken } from "../utils";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["dropzone", "items", "preview"];
  static values = { url: String, plan: String };

  // Create a single Notyf instance and set the max visible progress bars to 5
  notyf = new Notyf({
    duration: 0,
    position: { x: "right", y: "bottom" },
    ripple: false,
    dismissible: false,
    types: [
      {
        type: "success",
        icon: false,
        className: "uploadNotifications",
      },
    ],
  });

  maxVisibleNotifications = 5;

  connect() {
    this.notification = null;
    this.activeNotifications = [];
  }

  bindDropzone() {
    const notyfError = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });

    this.max_files_allowed = 100;

    const acceptedFiles = ["image/*"]; // Allows all image types

    // Check for "pro" or "agency" plan
    if (this.planValue == "pro" || this.planValue == "agency") {
      acceptedFiles.push(
        "application/pdf", // Allows PDF files
        "image/svg+xml", // Allows SVG files
        "application/postscript", // Allows EPS/AI files
        "application/octet-stream" // Generic MIME type, covers various types including PSD
      );
    }

    // Check for "agency" plan for additional Office documents
    if (this.planValue == "agency") {
      acceptedFiles.push("video/*");

      acceptedFiles.push("application/msword"); // .doc
      acceptedFiles.push(
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ); // .docx

      acceptedFiles.push("application/vnd.ms-excel"); // .xls
      acceptedFiles.push(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ); // .xlsx

      acceptedFiles.push("application/vnd.ms-powerpoint"); // .ppt
      acceptedFiles.push(
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ); // .pptx
    }

    const dropzoneConfig = {
      url: this.urlValue,
      method: "post",
      paramName: "item[media]",
      acceptedFiles: acceptedFiles.join(","),
      maxFiles: this.max_files_allowed,
      maxFilesize: 25,
      parallelUploads: 5,
      disablePreviews: true,
      headers: {
        "X-CSRF-Token": csrfToken(),
        Accept: "text/vnd.turbo-stream.html",
      },
    };

    const clickableElement = document.querySelector(".clickable");
    dropzoneConfig.clickable = clickableElement || false;

    if (this.dropzone) {
      this.dropzone.destroy();
      this.dropzone = null;
    }

    this.dropzone = new Dropzone(this.dropzoneTarget, dropzoneConfig);

    this.dropzone.on("maxfilesexceeded", (file) => {
      notyfError.error(
        `${file.name} not uploaded. You may only upload 6 files at a time`
      );
      this.dropzone.removeFile(file);
    });

    this.dropzone.on("addedfiles", (files) => {
      this.createNotification(files);

      if (files.length > this.max_files_allowed) {
        notyfError.error(
          `You may only upload ${this.max_files_allowed} files at a time`
        );
      }
    });

    this.dropzone.on("error", (file, error) => {
      if (this.isJSON(error)) {
        const errors = JSON.parse(error);
        notyfError.error(`Error Uploading ${file.name} - ${errors.errors}`);
      } else {
        notyfError.error(`Error Uploading ${file.name} - ${error}`);
      }

      this.dismissNotification(file);
    });

    this.dropzone.on("uploadprogress", (file, progress) => {
      this.updateProgress(file.upload.uuid, progress);
    });

    this.dropzone.on("sending", (file) => {
      this.createProgressNotification(file);
    });

    this.dropzone.on("addedfile", (file) => {
      if (this.dropzone.files.length > this.max_files_allowed) {
        this.dropzone.removeFile(file);
      }
    });

    this.dropzone.on("complete", (file) => {
      this.dropzone.removeFile(file);
      this.dismissNotification(file.upload.uuid);
    });

    this.dropzone.on("success", (file, html) => {
      Turbo.renderStreamMessage(html);
      this.dismissNotification(file.upload.uuid);
    });
  }

  isJSON(str) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  createNotification(files) {
    const progressHtml = `<div id='uploadNotification'></div>`;

    this.notification = this.notyf.success({
      message: progressHtml,
    });
  }

  createProgressNotification(file) {
    // Create a progress bar notification for the file
    const progressHtml = `
      Uploading ${file.name}<br>
      <div class="progress  mb-2" role="progressbar">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" data-dz-uploadprogress="${file.upload.uuid}" style="width: 0%"></div>
      </div>`;

    const div = document.createElement("div");
    div.id = file.upload.uuid;
    div.innerHTML = progressHtml;

    this.activeNotifications.push(file.upload.uuid);

    const notificationDiv = document.getElementById("uploadNotification");
    notificationDiv.append(div);

    if (this.activeNotifications.length > this.maxVisibleNotifications) {
      div.style.display = "none";
    }
  }

  updateProgress(uuid, progress) {
    const div = document.getElementById(uuid);
    div.style.display = "block";
    const node = document.querySelector(`[data-dz-uploadprogress="${uuid}"]`);
    if (node) {
      node.nodeName === "PROGRESS"
        ? (node.value = progress)
        : (node.style.width = `${progress}%`);
    }
  }

  dismissNotification(uuid) {
    // return true;

    const div = document.getElementById(uuid);
    if (div) {
      this.activeNotifications = this.activeNotifications.filter(
        (id) => id !== uuid
      );

      if (this.activeNotifications.length == 0) {
        this.notyf.dismissAll();
      }

      div.remove();
    }
  }
}
