import { Controller } from "@hotwired/stimulus";
import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/dom";

export default class extends Controller {
  static values = { extensionImg: String, projectsUrl: String };

  connect() {
    document.body.classList.add("wce-persist");

    // Select the meta tag with the name 'wecora-chrome-extension'
    const metaTag = document.querySelector(
      'meta[name="wecora-chrome-extension"]'
    );

    // Check if the meta tag exists
    if (metaTag) {
      let contentChangedToTrue = false;

      // Create a MutationObserver to observe attribute changes
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "content"
          ) {
            const newValue = metaTag.getAttribute("content");

            if (newValue === "true") {
              this.startTour();
              contentChangedToTrue = true;
              observer.disconnect(); // Stop observing since the change occurred
            }
          }
        });
      });

      // Start observing the meta tag for attribute changes (specifically the 'content' attribute)
      observer.observe(metaTag, {
        attributes: true, // Observe attribute changes
        attributeFilter: ["content"], // Only observe the 'content' attribute
      });

      // Set a timeout to trigger if the content doesn't change to 'true' within 3 seconds
      setTimeout(() => {
        if (!contentChangedToTrue) {
          this.startTour();
        }
      }, 3000);
    } else {
      console.log("Meta tag not found.");
    }
  }

  startTour() {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      classPrefix: "wecora-",
      defaultStepOptions: {
        classes: "shepherd-element",
        scrollTo: true,
      },
    });

    const wecoraMetaTag = document.querySelector(
      "meta[name=wecora-chrome-extension]"
    );

    console.log(wecoraMetaTag.content);

    if (wecoraMetaTag?.content != "true") {
      tour.addStep({
        id: "extensionNeeded",
        text: `
          <div class="p-5 text-center">
            <svg fill="none" height="47" viewBox="0 0 66 47" width="66" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="M21.11 45.1299C21.4464 45.5947 21.8861 45.9748 22.3946 46.2403C22.9032 46.5059 23.4664 46.6495 24.04 46.6599C24.2519 46.6402 24.4584 46.5826 24.65 46.49C27.7833 44.8305 30.6961 42.7846 33.32 40.4C34.2983 40.655 35.3149 40.7295 36.32 40.6199C39.5561 40.1868 42.6867 39.1704 45.56 37.6199C64.04 28.6199 54.04 12.17 65.34 -0.0400391C53.89 2.10996 32.34 8.62995 29.83 24.0399C28.43 32.6199 30.03 36.82 31.08 38.55C28.08 41.09 25.08 43 23.02 43.18H22.74H22.29C20.61 43.39 20.52 44.2599 21.11 45.1299ZM34.06 36.74C34.06 36.6 35.29 33.14 40.16 23.55C42.16 18.75 46.07 12.37 50.33 9.44995C52.6315 7.57168 55.2537 6.12508 58.07 5.17996C55.698 6.78299 53.5694 8.71957 51.75 10.93C48.29 14.72 46.16 19.9299 43.83 25.3699C38.77 35.2999 34.22 36.67 34.06 36.74Z" fill="#F33A39"></path>
                <path d="M32.48 10.2601C28.8094 13.7726 26.3568 18.3658 25.48 23.3701C24.7627 27.2349 24.6917 31.192 25.27 35.0801C6.09999 29.1801 13.02 11.4401 -0.0499878 1.3501C8.75001 1.5201 23.54 3.1801 32.48 10.2601Z" fill="#F57E6F"></path>
              </g>
              <defs>
                <clipPath id="clip0_21_43">
                  <rect fill="white" height="46.66" width="65.34"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>      
          <div class="mt-3 fs-4 fw-bold">Chrome Extension Required</div>
          <p class="mt-3">To use Wecora, you need to install our chrome extension. Click below to install it now for free.</div>
      `,
        buttons: [
          {
            text: "Install",
            action: () => {
              location.href =
                "https://chromewebstore.google.com/detail/wecora/hcnabjlhhjacmjhojponbnhijhadblll?authuser=0&hl=en";
            },
          },
        ],
        when: {
          show: () => {
            const stepElement = document.querySelector(".shepherd-step");
            // stepElement.classList.add("centered");
          },
        },
      });
    } else {
      tour.addStep({
        id: "intro",
        text: `
          <div class="p-5 text-center">
            <svg fill="none" height="47" viewBox="0 0 66 47" width="66" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="M21.11 45.1299C21.4464 45.5947 21.8861 45.9748 22.3946 46.2403C22.9032 46.5059 23.4664 46.6495 24.04 46.6599C24.2519 46.6402 24.4584 46.5826 24.65 46.49C27.7833 44.8305 30.6961 42.7846 33.32 40.4C34.2983 40.655 35.3149 40.7295 36.32 40.6199C39.5561 40.1868 42.6867 39.1704 45.56 37.6199C64.04 28.6199 54.04 12.17 65.34 -0.0400391C53.89 2.10996 32.34 8.62995 29.83 24.0399C28.43 32.6199 30.03 36.82 31.08 38.55C28.08 41.09 25.08 43 23.02 43.18H22.74H22.29C20.61 43.39 20.52 44.2599 21.11 45.1299ZM34.06 36.74C34.06 36.6 35.29 33.14 40.16 23.55C42.16 18.75 46.07 12.37 50.33 9.44995C52.6315 7.57168 55.2537 6.12508 58.07 5.17996C55.698 6.78299 53.5694 8.71957 51.75 10.93C48.29 14.72 46.16 19.9299 43.83 25.3699C38.77 35.2999 34.22 36.67 34.06 36.74Z" fill="#F33A39"></path>
                <path d="M32.48 10.2601C28.8094 13.7726 26.3568 18.3658 25.48 23.3701C24.7627 27.2349 24.6917 31.192 25.27 35.0801C6.09999 29.1801 13.02 11.4401 -0.0499878 1.3501C8.75001 1.5201 23.54 3.1801 32.48 10.2601Z" fill="#F57E6F"></path>
              </g>
              <defs>
                <clipPath id="clip0_21_43">
                  <rect fill="white" height="46.66" width="65.34"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>      
          <div class="mt-3 fs-4 fw-bold">Welcome to Wecora Hardware</div>
          <p class="mt-3">Ok so this is obviously just a fake product page to help you quickly learn how to save products.</div>
      `,
        buttons: [
          {
            text: "",
            action: tour.cancel,
          },
          {
            text: "Let's Begin",
            action: () => {
              document.getElementById("wecSummaryWindow").style =
                "animation: fadeIn 1s;opacity:1";
              tour.next();
            },
          },
        ],
        // This will prevent attaching to any element and centers the step
        when: {
          show: () => {
            const stepElement = document.querySelector(".shepherd-step");
            // stepElement.classList.add("centered");
          },
        },
      });

      tour.addStep({
        id: "quickAdd",
        title: "Quick Add",
        text: "When you're on certain product pages, our quick add appears letting you save the current product by clicking on +.",
        attachTo: {
          element: "#wecSummaryWindow",
          on: "top",
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20, crossAxis: 50 })],
        },
        buttons: [
          {
            text: "Back",
            action: tour.back,
          },
          {
            text: "Next",
            action: () => {
              document.getElementById("wecSummaryWindow").style.display =
                "none";
              tour.next();
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        useModalOverlay: false,
      });

      tour.addStep({
        id: "extensionBar",
        text: `
          <div class="mb-3">
            If quick add doesn't appear, it just means that the website you're looking at isn't set up to support it.
          </div>
          <div class="mb-3">
            No problem! You can capture a product or even a photo from any website by clicking the Wecora leaves from your 
            toolbar.
          </div>
          <div class="mb-3">
            If it's not there, click that little puzzle piece first and then the pin next to our leaves to add it.
          </div>
          <div class="p-3 text-center">
            <img src='${this.extensionImgValue}' alt='extension img'>
          </div>
          `,
        attachTo: {
          element: "#topTarget",
          on: "bottom",
        },
        buttons: [
          {
            text: "Back",
            action: tour.back,
          },
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });

      tour.addStep({
        id: "ctaExtensionBar",
        text: `Give it a try now. Go on - click it!`,
        attachTo: {
          element: "#topTarget",
          on: "bottom",
        },
      });

      tour.addStep({
        id: "extensionOpen",
        title: "Great Job!",
        text: `Nicely done!  Take a look at how Wecora captured all the relevant details from the page for you.<br><br>Go ahead and select your project and click Save.`,
        when: {
          show: () => {
            const stepElement = document.querySelector(".shepherd-step");
          },
        },
      });

      tour.addStep({
        id: "itemSaved",
        title: "Product Saved",
        text: `Congrats.  You just saved your first item to your library. Want to see it?  Click next below.`,
        buttons: [
          {
            text: "Next",
            action: () => {
              localStorage.setItem("tourActive", true);
              location.href = this.projectsUrlValue;
            },
          },
        ],
        when: {
          show: () => {
            const stepElement = document.querySelector(".shepherd-step");
          },
        },
      });
    }

    // Add more steps as needed

    tour.start();

    window.addEventListener("message", (event) => {
      if (event.source !== window) return;

      if (event.data.type === "WCE_SIDEPANEL_OPENED") {
        tour.show("extensionOpen");
      }

      if (event.data.type === "WCE_ITEM_SAVED") {
        tour.show("itemSaved");
      }
    });
  }
}
