import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="board-trash"
export default class extends Controller {
  static targets = ["items", "item"];
  static values = {
    url: String,
    objectUrl: String,
  };

  connect() {
    if (!this.hasItemsTarget) {
      return;
    }

    var sortable = Sortable.create(this.itemsTarget, {
      group: {
        name: "shared",
      },
      animation: 300,
      filter: ".no-drag",
      sort: false,
      onStart: (evt) => {
        document.body.classList.toggle("sortable-active");
        this.itemTargets.forEach((item) => {
          item.classList.remove("active");
        });
      },
      onMove: (evt) => {
        if (evt.to.classList.contains("no-drop")) {
          return false;
        }
      },
      onEnd: (evt) => {
        document.body.classList.toggle("sortable-active");

        const trigger = new CustomEvent("sortable-complete");
        window.dispatchEvent(trigger);

        if (evt.to === evt.from) {
          return false;
        }

        const itemId = evt.item.dataset.itemId;
        const url = evt.item.dataset.url;
        const containerId = evt.to.dataset.containerId;

        evt.item.style.opacity = 0.15;
        evt.item.id = itemId; // use this for replacing later

        this.restoreIdea(url, containerId);
      },
    });
  }

  add(ev) {
    ev.preventDefault();

    const container = document.querySelector(".tile_container_wrapper");
    const url = ev.params.url;
    const itemId = ev.params.itemId;
    const containerId = container.dataset.containerId;
    const tiles = container.querySelector(".tiles");

    // create div to replace...
    const div = document.createElement("div");
    div.style.opacity = 0.15;
    div.className = "sortable-ghost";
    div.id = itemId; // use this for replacing later
    tiles.prepend(div);

    this.restoreIdea(url, containerId);
    ev.currentTarget.closest(".grid-item").remove();
  }

  restoreIdea(url, containerId) {
    Turbo.fetch(url, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify({
        idea: { container_id: containerId },
      }),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }
}
