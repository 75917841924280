import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Notyf } from "notyf";
import { csrfToken } from "../utils";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["dropzone"];

  connect() {
    console.log("dz-projects#connect");

    const dropzoneConfig = {
      url: "/projects/items",
      method: "put",
      paramName: "item[attachments_attributes][][media]",
      autoQueue: false,
      clickable: false,
      acceptedFiles: "image/*,application/pdf",
      maxFiles: 1,
      previewsContainer: this.dropzoneTarget,
      headers: {
        "X-CSRF-Token": csrfToken(),
        Accept: "text/vnd.turbo-stream.html",
      },
    };

    this.dropzone.on("maxfilesexceeded", function (file) {
      alert("You can only upload a maximum of 1 file at a time");
      this.removeFile(file); // Optionally remove the extra file
    });

    this.dropzone = new Dropzone(this.dropzoneTarget, dropzoneConfig);

    this.dropzone.on("error", function (file, errorMessages) {
      window.notyf.error({
        message: `Error Uploading ${file.name}. - errorMessages`,
        dismissible: true,
      });
    });

    this.dropzone.on("addedfile", (file) => {
      this.dropzone.removeFile(file);
    });

    this.dropzone.on("complete", (file) => {
      this.dropzone.removeFile(file);
    });

    this.dropzone.on("success", (file, html) => {
      Turbo.renderStreamMessage(html);
    });
  }
}
