import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dashboard"
export default class extends Controller {
  static targets = ["greeting"];
  static values = { greetingName: String };

  connect() {
    this.greeting();
  }

  greeting() {
    var myDate = new Date();
    var hrs = myDate.getHours();

    var greet;

    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";

    this.greetingTarget.innerText = `${greet}, ${this.greetingNameValue}`;
  }
}
