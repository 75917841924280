import { Controller } from "@hotwired/stimulus";
import { headers } from "../utils";

// Connects to data-controller="public-tile"
export default class extends Controller {
  static targets = ["commentTextarea"];

  connect() {
    this.handlePermissions();

    this.element.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }

  handlePermissions() {
    const canvas = document.getElementById("canvas");

    const keys = ["n", "d", "pq", "uc", "lbl", "src"];
    const permitted = this.extractVPKeys(canvas);

    keys.forEach((key) => {
      if (!permitted.includes(key)) {
        this.remove(key);
      }
    });
  }

  extractVPKeys(canvas) {
    const result = [];

    for (let key in canvas.dataset) {
      if (key.startsWith("vp")) {
        const suffix = key.slice(2).toLowerCase();
        result.push(suffix);
      }
    }

    return result;
  }

  remove(permission) {
    this.element
      .querySelectorAll(`.d-vp-${permission}`)
      .forEach((el) => el.remove());
  }

  show(ev) {
    ev.preventDefault();

    // check for presentation view
    let url = ev.params.url;

    if (ev.params?.comments) {
      const params = new URLSearchParams({ comments: "true" });
      url = `${url}${url.includes("?") ? "&" : "?"}${params.toString()}`;
    }

    fetch(url, {
      method: "GET",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        document.body.classList.add("info-panel-active");
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  commentSubmit(ev) {
    this.commentTextareaTarget.value = "";
  }
}
