import { Controller } from "@hotwired/stimulus";
import VanillaContextMenu from "vanilla-context-menu";
import { Notyf } from "notyf";
import ActionSheet from "../modules/action_sheet";
import { headers, jsonHeaders, fadeOut } from "../utils";

// Connects to data-controller="boards"
export default class extends Controller {
  static values = { id: Number, url: String, owned: Boolean };

  connect() {
    this.contextMenu();
  }

  contextMenu() {
    const menuItems = [
      {
        label: "View",
        iconClass: "fa-regular fa-eye",
        callback: () => {
          // your code here
          location.href = this.urlValue;
        },
      },
    ];

    if (this.ownedValue) {
      menuItems.push("hr");
      menuItems.push({
        label: "Delete",
        callback: (ev) => {
          const as = new ActionSheet({
            title: "Delete Board",
            text: `Are you sure you want to delete this board? This action cannot be undone.`,
          });

          as.yesNoMenu(() => {
            this.destroy();
          });
        },
        iconClass: "fa fa-trash", // this only works if you have FontAwesome icons
      });
    }

    new VanillaContextMenu({
      scope: this.element,
      customThemeClass: "cm-bootstrap",
      transitionDuration: 0,
      menuItems: menuItems,
    });
  }

  destroy() {
    this.notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });

    this.notyf.success({
      message: "Deleting Board...",
      dismissible: true,
    });

    fetch(this.urlValue, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        fadeOut(this.element);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }
}
