import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="items-assign-to-board"
export default class extends Controller {
  static targets = ["field", "btn", "labels", "label", "placeholder"];
  static values = { url: String, addDisabled: Boolean };

  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false,
    });

    this.element.addEventListener("show.bs.modal", (ev) => {
      this.itemId = ev.relatedTarget.dataset.itemId;
      this.image = ev.relatedTarget.dataset.image;

      this.init();
    });
  }

  init() {
    if (this.itemId) {
      if (this.image) {
        this.placeholderTarget.innerHTML = `<img src="${this.image}" class="img-thumbnail rounded" alt="Image">`;
      } else {
        this.placeholderTarget.innerHTML = `Select or enter name for new labels to assign to this item.`;
      }
    } else {
      const items = document.querySelectorAll("#items .item.selected");
      this.placeholderTarget.innerHTML = `Select or enter name for new labels to assign to the ${items.length} selected items.`;
    }

    const url = this.urlValue;

    (async () => {
      const resp = await fetch(`${url}`);
      const options = await resp.json();

      const config = {
        selector: () => {
          return this.fieldTarget;
        },
        placeHolder: "Select or add labels...",
        data: {
          src: options,
          keys: ["name"],
        },
        threshold: 0,
        resultsList: {
          maxResults: undefined,
          element: (list, data) => {
            if (!data.results.length && data.query.length >= 3) {
              const message = document.createElement("div");
              message.classList.add(
                "autoComplete_result_0",
                "no_result",
                "p-4",
                "cursor-pointer"
              );

              if (!this.addDisabledValue) {
                message.innerHTML = `<span>Add label <strong>"${data.query}"...</strong></span>`;
                list.prepend(message);

                message.addEventListener("click", () => {
                  this.autoCompleteLabel.close();
                  this.addNewLabel(data.query);
                });
              } else {
                const message = document.createElement("div");
                message.classList.add(
                  "autoComplete_result_0",
                  "no_result",
                  "p-4",
                  "cursor-pointer"
                );
                message.innerHTML = `<span><strong>"${data.query}"</strong> not found</span>`;
                list.prepend(message);
              }
            }
          },
          noResults: true,
        },
        resultItem: {
          highlight: true,
        },
        events: {
          input: {
            init: (event) => {
              this.fieldTarget.classList.remove("d-none");
            },
            focus: (event) => {
              this.autoCompleteLabel.start();
            },
            selection: (event) => {
              console.log(event.detail.selection.value);
              const selection = event.detail.selection.value;
              this.createLabel(selection);
              this.fieldTarget.blur();
            },
          },
        },
      };

      this.autoCompleteLabel = new autoComplete(config);
    })();

    return true;
  }

  addNewLabel(val) {
    window.notyf.success({
      message: "Adding Label...",
      dismissible: true,
    });

    const body = {
      label: {
        name: val,
      },
    };

    fetch(this.urlValue, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((json) => {
        console.log("body", json);
        this.createLabel(json);
        this.fieldTarget.value = "";
      })
      .catch((error) => {
        window.notyf.error({
          message: `Error adding label - ${error}`,
          dismissible: true,
        });
      });
  }

  createLabel(obj) {
    const labels = this.labelTargets.map((label) => label.dataset.id);
    console.log("createLabel", obj, labels);

    const exists = this.labelTargets.find((label) => {
      return label.dataset.id.toString() === obj.id.toString();
    });

    console.log("exists", exists);

    if (!exists) {
      const div = document.createElement("div");
      div.classList.add(
        "btn",
        "btn-outline-primary",
        "btn-sm",
        "d-flex",
        "justify-content-between",
        "align-items-center",
        "mx-1",
        "label"
      );
      div.dataset.id = obj.id;
      div.dataset.itemsAssignLabelsTarget = "label";

      const p2 = document.createElement("div");
      p2.classList.add("pe-2");
      p2.innerText = obj.name;
      div.append(p2);

      const p3 = document.createElement("div");
      p3.classList.add("ps-2");
      p3.innerHTML = "<em class='fa-solid fa-close'>";
      p3.dataset.action = "click->items-assign-labels#removeLabel";
      div.append(p3);

      this.labelsTarget.append(div);

      this.labelsTarget.classList.remove("d-none");
      this.btnTarget.classList.remove("disabled");
    }
  }

  removeLabel(ev) {
    ev.currentTarget.closest(".label").remove();

    if (this.labelTargets.length == 0) {
      this.labelsTarget.classList.add("d-none");
      this.btnTarget.classList.add("disabled");
    }
  }

  assign() {
    console.log("assign");

    if (this.labelTargets.length > 0) {
      window.notyf.success({
        message: "Assigning labels...",
        dismissible: true,
      });

      let items = [];
      if (this.itemId) {
        items = [this.itemId];
      } else {
        document
          .querySelectorAll("#items .item.selected")
          .forEach((item) => items.push(item.dataset.itemId));
      }

      let index = 0;

      const labels = this.labelTargets.map((label) => label.dataset.id);

      items.forEach((itemId) => {
        const body = {
          item_label: {
            label_ids: labels,
          },
        };

        const url = `/items/${itemId}/labels`;

        fetch(url, {
          method: "POST",
          headers: jsonHeaders(),
          body: JSON.stringify(body),
        })
          .then((resp) => resp.json())
          .then((json) => {
            console.log("body", json);

            index++;
            if (index == items.length) {
              window.notyf.dismissAll();

              // now close everything and remove labels
              this.cancel();
            }
          })
          .catch((error) => {
            index++;
            window.notyf.error({
              message: `Error assigning labels - ${error}`,
              dismissible: true,
            });
          });
      });
    } else {
      console.log("error - no labels selected");
    }
  }

  cancel(ev) {
    this.modal.hide();
    this.fieldTarget.value = "";
    this.btnTarget.classList.add("disabled");
    this.fieldTarget.classList.add("d-none");
    this.labelsTarget.replaceChildren();
    this.autoCompleteLabel.unInit();

    if (this.itemId) {
      this.itemId = null;
      document.getElementById("details")?.reload();
    } else {
      const trigger = new CustomEvent("unselectAll");
      window.dispatchEvent(trigger);
    }
  }
}
