import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="item-permissions"
export default class extends Controller {
  static targets = ["option"];
  static values = { url: String };

  connect() {}

  submit(ev) {
    ev.preventDefault();

    // generate share link
    const data = {};
    this.optionTargets.forEach((el) => {
      data[el.dataset.name] = el.checked;
    });

    console.log("data", data);

    fetch(this.urlValue, {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify({ item: { view_permissions: data } }),
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((body) => {
            console.log(body);
          });
        }
      })
      .catch((err) => console.log(err));
  }
}
