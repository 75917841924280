import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("error", this.handleError.bind(this));
    window.addEventListener(
      "unhandledrejection",
      this.handlePromiseRejection.bind(this)
    );
  }

  disconnect() {
    window.removeEventListener("error", this.handleError.bind(this));
    window.removeEventListener(
      "unhandledrejection",
      this.handlePromiseRejection.bind(this)
    );
  }

  handleError(event) {
    if (
      event.error instanceof TypeError &&
      event.error.message === "Failed to fetch"
    ) {
      this.showReloadAlert();
    }
  }

  handlePromiseRejection(event) {
    if (
      event.reason instanceof TypeError &&
      event.reason.message === "Failed to fetch"
    ) {
      this.showReloadAlert();
    }
  }

  showReloadAlert() {
    // if (
    //   confirm(
    //     "Sorry there was an error loading this page. Please refresh your browser."
    //   )
    // ) {
    //   window.location.reload();
    // }
    console.log("reload alert");
  }
}
