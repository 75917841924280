import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="item-label"
export default class extends Controller {
  static targets = ["field", "btn", "labels", "label"];
  static values = {
    labelUrl: String,
    url: String,
    labels: Array,
    addDisabled: Boolean,
  };

  connect() {
    this.element.style.minHeight = "500px";

    (async () => {
      try {
        const source = await fetch(this.labelUrlValue);
        const data = await source.json();
        this.init(data);
      } catch (error) {
        return error;
      }
    })();
  }

  init(data) {
    const config = {
      selector: () => {
        return this.fieldTarget;
      },
      placeHolder: "Select or add labels...",
      name: "bs_autoComplete",
      data: {
        src: data,
        keys: ["name"],
      },
      threshold: 0,
      resultsList: {
        maxResults: undefined,
        element: (list, data) => {
          if (!data.results.length && data.query.length >= 3) {
            const message = document.createElement("div");
            message.classList.add(
              "autoComplete_result_0",
              "no_result",
              "p-4",
              "cursor-pointer"
            );

            if (!this.addDisabledValue) {
              message.innerHTML = `<span>Add label <strong>"${data.query}"...</strong></span>`;
              list.prepend(message);

              message.addEventListener("click", () => {
                this.autoCompleteLabel.close();
                this.addNewLabel(data.query);
              });
            } else {
              const message = document.createElement("div");
              message.classList.add(
                "autoComplete_result_0",
                "no_result",
                "p-4",
                "cursor-pointer"
              );
              message.innerHTML = `<span><strong>"${data.query}"</strong> not found</span>`;
              list.prepend(message);
            }
          }
        },
        noResults: true,
      },
      resultItem: {
        highlight: true,
      },
      events: {
        input: {
          init: (event) => {
            this.fieldTarget.classList.remove("d-none");
          },
          focus: () => {
            this.autoCompleteLabel.start();
          },
          selection: (event) => {
            const selection = event.detail.selection.value;
            this.createLabel(selection);
            this.fieldTarget.blur();
          },
          open: (event) => {
            event.target.nextSibling.scrollIntoView();
          },
        },
      },
    };

    this.autoCompleteLabel = new autoComplete(config);
  }

  addNewLabel(val) {
    window.notyf.success({
      message: "Adding Label...",
      dismissible: true,
    });

    const body = {
      label: {
        name: val,
      },
    };

    fetch(this.labelUrlValue, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((json) => {
        this.createLabel(json);
        this.fieldTarget.value = "";
      })
      .catch((error) => {
        window.notyf.error({
          message: `Error adding label - ${error}`,
          dismissible: true,
        });
      });
  }

  createLabel(obj) {
    const exists = this.labelTargets.find((label) => {
      return label.dataset.id.toString() === obj.id.toString();
    });

    if (!exists) {
      const div = document.createElement("div");
      div.classList.add(
        "btn",
        "btn-outline-primary",
        "btn-sm",
        "d-flex",
        "justify-content-between",
        "align-items-center",
        "mx-1",
        "mb-1",
        "label"
      );
      div.dataset.id = obj.id;
      div.dataset.boardLabelsTarget = "label";

      const p2 = document.createElement("div");
      p2.classList.add("pe-2");
      p2.innerText = obj.name;
      div.append(p2);

      const p3 = document.createElement("div");
      p3.classList.add("ps-2");
      p3.innerHTML = "<em class='fa-solid fa-close'>";

      p3.addEventListener("click", () => {
        this.removeLabel(div);
      });

      div.append(p3);

      this.labelsTarget.append(div);

      this.labelsTarget.classList.remove("d-none");
      this.btnTarget.classList.remove("d-none");
    }
  }

  removeLabel(label) {
    label.remove();

    if (this.labelTargets.length == 0) {
      this.labelsTarget.classList.add("d-none");
      this.btnTarget.classList.add("d-none");
    }
  }

  assign() {
    if (this.labelTargets.length > 0) {
      window.notyf.success({
        message: "Adding labels...",
        dismissible: true,
      });

      const labels = this.labelTargets.map((label) => label.dataset.id);

      const body = {
        label: {
          ids: labels,
        },
      };

      fetch(this.urlValue, {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(body),
      })
        .then((resp) => resp.text())
        .then((html) => {
          window.notyf.dismissAll();
          Turbo.renderStreamMessage(html);
        })
        .catch((error) => {
          window.notyf.error({
            message: `Error assigning labels - ${error}`,
            dismissible: true,
          });
        });
    } else {
      console.log("error - no labels selected");
    }
  }

  delete(ev) {
    ev.preventDefault();

    const url = ev.currentTarget.href;

    fetch(url, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }
}
