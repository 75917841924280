import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["project", "nav", "search"];
  static values = { dashboard: String };

  connect() {
    localStorage.setItem("previousUrl", this.dashboardValue);

    window.onkeydown = (e) => {
      if (e.metaKey && e.key == "k") {
        this.searchTarget.focus();
        this.searchTarget.select();
      }
    };

    window.scrollTo(0, 0);
  }

  projectSelected(ev) {
    document.body.classList.remove("info-panel-active");

    this.navTargets.forEach((el) => {
      el.classList.remove("active");

      if (ev.params.hasOwnProperty("id")) {
        if (el.dataset.projectsIdParam == ev.params.id) {
          el.classList.add("active");
        }
      }
    });

    // store in localstorage for search
    localStorage.setItem("previousUrl", ev.currentTarget.href);

    if (ev.params.hasOwnProperty("id")) {
      // reset add new button with current project
      Turbo.visit(ev.params.addNewUrl, { frame: "add_new" });
    } else {
      this.navTarget.classList.add("active"); //dashboard
    }
  }

  filter(ev) {
    this.projectTargets.forEach((project) => {
      if (
        project.dataset.name
          .toLowerCase()
          .includes(ev.currentTarget.value.toLowerCase())
      ) {
        project.style.display = "block";
      } else {
        project.style.display = "none";
      }
    });
  }
}
