import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="field-new"
export default class extends Controller {
  connect() {}

  submitted(ev) {
    document.getElementById("new_item_fields")?.reload();
    document.getElementById("fields")?.reload();
  }
}
