import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["colorInput", "btn"];

  connect() {
    console.log("color-dropdown#connect");
  }

  select(event) {
    console.log("select");

    event.preventDefault();
    const color = event.currentTarget.dataset.color;

    console.log(color);
    this.colorInputTarget.value = color;
    this.btnTarget.style.backgroundColor = color;
  }
}
