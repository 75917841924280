import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="status-labels"
export default class extends Controller {
  static targets = ["field"];
  static values = { autoSubmit: Boolean };

  connect() {
    console.log("status-labels#connect", this.autoSubmitValue);
  }

  selected(ev) {
    ev.preventDefault();

    this.fieldTarget.value = ev.params.color;

    const colorDiv = document.getElementById(ev.params.target);
    colorDiv.style.backgroundColor = ev.params.color;

    if (this.autoSubmitValue) {
      this.element.requestSubmit();
    }
  }
}
