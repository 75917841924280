import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";
import { projectSelector, jsonHeaders } from "../utils";

// Connects to data-controller="idea-copy"
export default class extends Controller {
  static targets = ["finder", "msg", "btn"];
  static values = { itemId: String };

  connect() {}

  init() {}

  finderSelected(ev) {
    if (ev.detail.group) {
      this.groupId = ev.detail.group;
      this.btnTarget.classList.remove("d-none");
    } else {
      this.btnTarget.classList.add("d-none");
    }
  }

  copy() {
    if (this.groupId) {
      window.notyf.success({
        message: "Copying item...",
        dismissible: true,
      });

      const url = `/containers/${this.groupId}/ideas.json`;

      const body = {
        idea: {
          item_id: this.itemIdValue,
        },
      };

      fetch(url, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => resp.json())
        .then((json) => {
          console.log("resp", json);
          window.notyf.dismissAll();
          window.notyf.success(
            `Item copied to <a href="/boards/${json.board.token}" data-turbo-frame="_top">${json.board.name}</a>`
          );

          const modal = bootstrap.Modal.getInstance("#bsTurboModal");
          modal.hide();
        })
        .catch((error) => {
          window.notyf.error({
            message: `Error copying - ${error}`,
            dismissible: true,
          });
        });
    }
  }
}
