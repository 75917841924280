import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="templates"
export default class extends Controller {
  static targets = ["field", "template"];

  connect() {}

  select(ev) {
    this.templateTargets.forEach((t) => {
      t.classList.remove("active");
    });

    ev.currentTarget.classList.add("active");
    this.fieldTarget.value = ev.params.value;
  }
}
