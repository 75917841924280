import { Controller } from "@hotwired/stimulus";
import { fabric } from "fabric";

// Connects to data-controller="moodboard"
export default class extends Controller {
  static targets = ["canvas", "modal"];
  static values = { obj: Object };

  connect() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const canvas = new fabric.Canvas(this.canvasTarget, {
      selection: false,
      stroke: "#f0eee4",
      backgroundColor: "white",
      strokeWidth: 0,
      shadow: "0px 2px 2px rgba(50, 50, 50, 0.5)",
      width: width,
      height: height,
    });

    canvas.loadFromJSON(this.objValue, (ev) => {
      canvas.getObjects().forEach((obj) => {
        obj.set({
          selectable: false, // Ensure the object is not selectable
          hoverCursor: "pointer", // Change the cursor to pointer on hover
        });

        obj.on("mousedown", (event) => {
          if (obj.url) {
            Turbo.visit(obj.url, { frame: "info_panel" });
            document.body.classList.add("info-panel-active");
          }
        });
      });
    });

    window.addEventListener("resize", (ev) => {
      this.resizeCanvas(canvas);
    });

    this.resizeCanvas(canvas);

    if (this.hasModalTarget) {
      var myModal = new bootstrap.Modal(this.modalTarget);
      myModal.show();
    }
  }

  resizeCanvas(canvas) {
    const width = window.innerWidth;

    const scaleFactor = (width - 777) / 777 + 1;
    canvas.setHeight(scaleFactor * 600);
    canvas.setZoom(scaleFactor);
    canvas.renderAll.bind(canvas);
    canvas.calcOffset();
  }
}
