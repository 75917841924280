import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="offcanvas"
export default class extends Controller {
  connect() {
    console.log("offcanvas#connect");

    // Ensure the Offcanvas is properly closed before caching the page
    document.addEventListener("turbo:before-cache", function () {
      var offcanvasInstance = bootstrap.Offcanvas.getInstance(this.element);
      if (offcanvasInstance) {
        offcanvasInstance.hide(); // Trigger Bootstrap's hide method
      }
    });

    // Make sure the backdrop is always cleaned up when Offcanvas is closed
    document.addEventListener("hidden.bs.offcanvas", function () {
      var backdrop = document.querySelector(".offcanvas-backdrop");
      if (backdrop) {
        backdrop.remove(); // Ensure the backdrop is removed after the offcanvas is fully hidden
      }
    });

    // Reinitialize the Offcanvas when the page is loaded to ensure proper functioning
    document.addEventListener("turbo:load", function () {
      console.log("offcanvas#turbo:load");
      const offcanvas = new bootstrap.Offcanvas(this.element);
      offcanvas.getOrCreateInstance();
    });
  }
}
