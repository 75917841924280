import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="items-assign-to-board"
export default class extends Controller {
  static targets = ["placeholder", "btn", "finder", "msg"];
  static values = { itemIds: Array };

  connect() {
    console.log("items-add-to-board#connect", this.itemIdsValue);

    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false,
    });

    this.element.addEventListener("show.bs.modal", (ev) => {
      Turbo.visit("/finder", { frame: "finder" });
      this.msgTarget.classList.add("d-none");
      this.finderTarget.classList.remove("d-none");
    });
  }

  init() {}

  finderSelected(ev) {
    if (ev.detail.group) {
      this.groupId = ev.detail.group;
      this.btnTarget.classList.remove("d-none");
    } else {
      this.btnTarget.classList.add("d-none");
    }
  }

  assign() {
    window.notyf.success({
      message: "Assigning item to board...",
      dismissible: true,
    });

    const url = `/containers/${this.groupId}/ideas.json`;

    let items = this.itemIdsValue;

    let index = 0;

    items.forEach((itemId) => {
      const body = {
        idea: {
          item_id: itemId,
        },
      };

      fetch(url, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => resp.json())
        .then((json) => {
          index++;

          if (index == items.length) {
            window.notyf.dismissAll();
            document.getElementById("boards")?.reload();

            this.msgTarget.innerHTML = `The item(s) have been added to the selected board. <a href="/boards/${json.board.token}" data-turbo-frame="_top">Click here</a> to view the board now.`;
            this.msgTarget.classList.remove("d-none");
            this.finderTarget.classList.add("d-none");
            this.btnTarget.classList.add("d-none");
          }
        })
        .catch((error) => {
          index++;
          window.notyf.error({
            message: `Error assigning to board - ${error}`,
            dismissible: true,
          });
        });
    });
  }

  cancel(ev) {
    this.modal.hide();

    if (this.itemIdsValue.length == 1) {
      document.getElementById("details")?.reload();
    } else {
      const trigger = new CustomEvent("unselectAll");
      window.dispatchEvent(trigger);
    }
  }
}
