import {
  Controller
} from "@hotwired/stimulus"

// some links are "current": (i.e. the current page you are on)
// other links are non-current (i.e. point to a page that you are not currently on)

export default class extends Controller {
  static targets = ["link"]

  connect() {
    console.log('tabs#connect')
  }

  select(ev) {
    this.linkTargets.forEach(link => {
      link.classList.remove('active');
    })

    ev.currentTarget.classList.add('active');
  }
}