import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Notyf } from "notyf";
import { csrfToken } from "../utils";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["dropzone"];
  static values = {
    url: String,
  };

  connect() {
    if (!this.hasDropzoneTarget) {
      return;
    }

    const dropzoneConfig = {
      url: this.urlValue,
      method: "put",
      paramName: "item[attachments_attributes][][media]",
      clickable: true,
      acceptedFiles: "image/*,application/pdf",
      maxFiles: 1,
      previewsContainer: this.dropzoneTarget,
      headers: {
        "X-CSRF-Token": csrfToken(),
        Accept: "text/vnd.turbo-stream.html",
      },
    };

    this.dropzone = new Dropzone(this.dropzoneTarget, dropzoneConfig);

    this.dropzone.on("error", function (file, errorMessages) {
      const id = `sb_${file.size}`;

      const notyf = new Notyf({
        duration: 4000,
        position: { x: "right", y: "top" },
      });
      notyf.error({
        message: `Error Uploading ${file.name}.`,
        dismissible: true,
      });
    });

    this.dropzone.on("complete", (file) => {
      this.dropzone.removeFile(file);
    });

    this.dropzone.on("success", (file, html) => {
      Turbo.renderStreamMessage(html);
    });
  }
}
