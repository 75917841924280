import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="comment-sortable"
export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    this.sortable();
  }

  sortable(el) {
    var sortable = Sortable.create(this.element, {
      group: {
        name: "shared",
        pull: "clone",
      },
      animation: 300,
      sort: false,
      onStart: (evt) => {
        evt.item.classList.add("grid-item");
        document.body.classList.toggle("sortable-active");
      },
      onMove: (evt) => {
        if (evt.to.classList.contains("no-drop")) {
          return false;
        }
      },
      onEnd: (evt) => {
        document.body.classList.toggle("sortable-active");

        const trigger = new CustomEvent("sortable-complete");
        window.dispatchEvent(trigger);

        if (evt.to === evt.from) {
          return false;
        }

        const url = evt.to.dataset.ideaUrl;
        const position = evt.newIndex;

        const body = {
          item: {
            name: evt.item.dataset.name,
            media_url: evt.item.dataset.url,
          },
        };

        evt.item.style.opacity = 0.35;
        evt.item.classList.add("rounded-4");
        evt.item.classList.add("sortable-dropped");

        this.createItem(body)
          .then((resp) => {
            if (resp.ok) {
              resp.json().then((json) => {
                evt.item.id = `dropped_item_${json.fingerprint}`; // use this for replacing later

                this.createIdea(url, json.id, position);
              });
            } else {
              resp.json().then((json) => {
                evt.item.remove();
                console.error("create item error", json);
                window.notyf.error(json.errors);
              });
            }
          })
          .catch((error) => {
            evt.item.remove();
            console.error("error", error);
            window.notyf.error(error.errors);
          });
      },
    });
  }

  createItem(body) {
    return new Promise((resolve, reject) => {
      fetch(this.urlValue + ".json", {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createIdea(url, itemId, position) {
    Turbo.fetch(url, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify({
        idea: { item_id: itemId, position: position },
      }),
    })
      .then((resp) => resp.text())
      .then((html) => {
        // evt.item.remove();
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }
}
