import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="upload-button"
export default class extends Controller {
  static targets = ["field", "image"];

  connect() {}

  click(ev) {
    ev.preventDefault();

    this.fieldTarget.click();
  }

  preview(ev) {
    this.imageTarget.src = window.URL.createObjectURL(ev.target.files[0]);
  }

  save(ev) {
    this.imageTarget.src = window.URL.createObjectURL(ev.target.files[0]);

    this.element.requestSubmit();
  }
}
