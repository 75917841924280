import { Controller } from "@hotwired/stimulus";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="labels"
export default class extends Controller {
  connect(ev) {
    Coloris({
      focusInput: false,
      closeButton: true,
      closeLabel: "Select",
      swatches: [
        "#264653",
        "#2a9d8f",
        "#e9c46a",
        "rgb(244,162,97)",
        "#e76f51",
        "#d62828",
        "navy",
        "#07b",
        "#0096c7",
        "#00b4d880",
        "rgba(0,119,182,0.8)",
      ],
      onChange: (color, input) => {
        input.style.color = color;
        input.style.backgroundColor = color;
      },
      onSelected: (color, input) => {
        fetch(input.dataset.url, {
          method: "PUT",
          headers: jsonHeaders(),
          body: JSON.stringify({ label: { color: color } }),
        }).catch((error) => {
          console.error("error", error);
        });
      },
    });
  }
}
