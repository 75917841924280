import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="quickbooks"
export default class extends Controller {
  static targets = ["selectBox", "incomeAccount", "inventoryAccount"];
  static values = { url: String, metadata: Object };

  connect() {}

  setIncomeAccount(ev) {
    console.log(
      "quickbooks#setIncomeAccount",
      ev.target.value,
      this.metadataValue
    );

    const metadata = this.metadataValue;
    metadata["quickbooks_income_account_id"] = this.incomeAccountTarget.value;
    // metadata["quickbooks_inventory_assets_account_id"] =
    //   this.inventoryAccountTarget.value;

    console.log(metadata);

    fetch(this.urlValue, {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify({ company: { metadata } }),
    })
      .then((resp) => {
        this.selectBoxTarget.className = "text-center mt-3";

        window.notyf.success({
          message: "Quickbooks Income Account Saved",
          dismissible: true,
        });
      })
      .catch((error) => {
        window.notyf.error({
          message: error,
          dismissible: true,
        });
      });
  }
}
