import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comments"
export default class extends Controller {
  static targets = ["field", "btn"];

  connect() {}

  focus(ev) {
    this.btnTarget.classList.remove("d-none");
  }
}
