import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = { content: String, message: String };

  connect() {}

  copy(ev) {
    ev.preventDefault();

    navigator.clipboard.writeText(this.contentValue);

    const notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });
    notyf.success({
      message: this.messageValue,
      dismissible: true,
    });
  }
}
