import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="checkboxes"
export default class extends Controller {
  static targets = ["checkbox"];

  connect() {}

  toggleAll(ev) {
    this.checkboxTargets.forEach(
      (el) => (el.checked = ev.currentTarget.checked)
    );
  }

  toggle(ev) {
    if (ev.target.tagName === "INPUT" && ev.target.type === "checkbox") {
      return;
    }

    ev.currentTarget
      .querySelectorAll("input.form-check-input")
      .forEach((el) => (el.checked = !el.checked));
  }
}
