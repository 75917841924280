import { Controller } from "@hotwired/stimulus";
import alertify from "alertifyjs";
import ActionSheet from "../modules/action_sheet";
import { headers, jsonHeaders } from "../utils";

// Connects to data-controller="project"
export default class extends Controller {
  static targets = ["name"];
  static values = { name: String, url: String, id: String };

  connect() {}

  rename(ev) {
    ev.preventDefault();

    const originalName = this.nameValue.trim().toLowerCase();

    alertify.defaults.glossary.title = "Rename Project";
    alertify.prompt("", this.nameValue, (ev, value) => {
      if (value !== "") {
        const body = {
          project: {
            name: value,
          },
        };

        fetch(this.urlValue + ".json", {
          method: "PUT",
          headers: headers(),
          body: JSON.stringify(body),
        })
          .then((resp) => {
            if (!resp.ok) {
              // This will handle 409, 400, and any other error status
              return resp.json().then((errors) => {
                window.notyf.error({
                  message: errors.errors,
                  dismissible: true,
                });
              });
            } else {
              resp.json().then(() => {
                document.getElementById("content").reload();

                document.querySelectorAll(".project_name").forEach((el) => {
                  if (originalName === el.innerText.toLowerCase().trim()) {
                    el.innerText = value;
                  }
                });
              });
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    });
  }

  archive(ev) {
    ev.preventDefault();

    const as = new ActionSheet({
      title: "Archive Project",
      text: `Are you sure you want to archive this project?`,
    });

    as.yesNoMenu(() => {
      this.handleArchive(true);
    });
  }

  unarchive(ev) {
    ev.preventDefault();
    this.handleArchive(false);
  }

  handleArchive(state) {
    const body = {
      project: {
        archived: state,
      },
    };

    window.notyf.success({
      message: state ? "Archiving Project..." : "Unarchiving Project...",
      dismissible: true,
    });
    fetch(this.urlValue, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);

        window.notyf.dismissAll();
        window.notyf.success({
          message: state ? "Project Archive!" : "Project Unarchive!",
          dismissible: true,
        });

        document.getElementById("content").reload();
      })
      .catch((err) => {
        console.log("handleArchive Error", err);
        window.notyf.dismissAll();
        window.notyf.error({
          message: state
            ? "There was a problem archiving this project.  Please contact support."
            : "There was a problem unarchiving this project.  Please contact support.",
          dismissible: true,
        });
      });
  }

  transfer(ev) {
    ev.preventDefault();

    const name = ev.currentTarget.innerText;
    const id = ev.params.transferToId;

    const as = new ActionSheet({
      title: "Transfer Project Ownership",
      text: `Are you sure you want to transfer project ownership to <strong>${name}</strong>? <br/>This will give them full control of all items and boards in the project`,
    });

    as.yesNoMenu(() => {
      window.notyf.success({
        message: "Transfering Project...",
        dismissible: true,
      });

      const body = {
        project: {
          professional_id: id,
        },
      };

      fetch(this.urlValue + ".json", {
        method: "PUT",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => resp.text())
        .then((html) => {
          Turbo.renderStreamMessage(html);

          window.notyf.dismissAll();
          window.notyf.success({
            message: "Project Transfered!",
            dismissible: true,
          });

          document
            .getElementById("sidebar")
            .querySelector(".project_list a")
            .click();

          const modal = bootstrap.Modal.getInstance("#bsTurboModal");
          modal.hide();
        })
        .catch((err) => {
          window.notyf.dismissAll();
          window.notyf.error({
            message:
              "There was a problem transfering this project.  Please contact support.",
            dismissible: true,
          });
        });
    });
  }

  delete(ev) {
    ev.preventDefault();

    const as = new ActionSheet({
      title: "Delete Project",
      text: `Are you sure you want to delete this project? This action cannot be undone.`,
    });

    as.yesNoMenu(() => {
      window.notyf.error({
        message: "Deleteing Project...",
        dismissible: true,
      });
      this.destroy();
    });
  }

  destroy() {
    fetch(this.urlValue, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        window.notyf.dismissAll();
        window.notyf.success({
          message: "Project Deleted!",
          dismissible: true,
        });

        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        window.notyf.dismissAll();
        window.notyf.error({
          message:
            "There was a problem deleting this project.  Please contact support.",
          dismissible: true,
        });
      });
  }
}
