// app/javascript/controllers/load_more_controller.js
import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import { get } from "@rails/request.js";

// Connects to data-controller="load-more"
export default class extends Controller {
  static values = {
    listId: String,
    url: String,
    placeholder: Boolean,
    placeholderClasslist: String,
  };

  connect() {
    this.page = 1;
    this.fetchingData = false;
    // useIntersection(this);
    useIntersection(this, {
      threshold: this.thresholdValue || 0.5, // Change this value based on your needs
      onEnter: this.handleEnter.bind(this),
      onLeave: this.handleLeave.bind(this),
    });
  }

  handleEnter(entry) {
    // Check if the element is not already appeared
    if (!this.appeared) {
      this.appeared = true;
      this.appear(); // Call your appear function here
    }
  }

  handleLeave(entry) {
    // Reset the appeared flag when it leaves the viewport
    this.appeared = false;
  }

  async appear() {
    if (this.fetchingData || this.hasNoMoreResultsItem) return;
    this.fetchingData = true;
    this.page = this.page + 1;

    let divs = [];
    if (this.placeholderValue) {
      for (let index = 0; index < 3; index++) {
        const div = document.createElement("div");
        div.className = this.placeholderClasslistValue;
        div.innerHTML = "<div class='placeholder w-100 h-100'></div>";
        document.getElementById(this.listIdValue)?.append(div);
        divs.push(div);
      }
    }

    await get(this.urlValue, {
      query: {
        page: this.page,
        turbo_target: this.listIdValue,
      },
      responseKind: "turbo-stream",
    });

    if (this.placeholderValue) {
      for (let index = 0; index < divs.length; index++) {
        divs[index].remove();
      }
    }

    this.fetchingData = false;
  }

  get hasNoMoreResultsItem() {
    return (
      document
        .getElementById(this.listIdValue)
        .querySelector(`[data-no-more-results]`) != null
    );
  }
}
