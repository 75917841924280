import { Controller } from "@hotwired/stimulus";
import { jsonHeaders } from "../utils";

// Connects to data-controller="settings"
export default class extends Controller {
  static values = { professionalUrl: String, aiUrl: String };

  connect() {}

  aiToggle(ev) {
    ev.preventDefault();

    fetch(this.professionalUrlValue, {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify({
        professional: {
          ai_naming: ev.target.checked,
        },
      }),
    })
      .then(() => {
        window.notyf.success("Settings updated");
      })
      .catch((err) => {
        window.notyf.error(err);
        console.error(err);
      });
  }

  startAi() {
    fetch(this.aiUrlValue, {
      method: "POST",
      headers: jsonHeaders(),
    })
      .then(() => {
        window.notyf.success("Indexing started");
      })
      .catch((err) => {
        window.notyf.error(err);
        console.error(err);
      });
  }
}
